import RJS379ANRMLog from "../data/RJS-739A_NMR_LOG.json";

export const API_URL = "https://petwin-api.by.felipemfp.dev";
// export const API_URL = "http://127.0.0.1:8000";

export function getJWT() {
  return sessionStorage.getItem("petwin_token");
}

export async function authLogin({ username, password }) {
  const response = await fetch(`${API_URL}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": `application/json`,
    },
    body: JSON.stringify({ username, password }),
  });
  return response.json();
}

export async function authRefresh() {
  const response = await fetch(`${API_URL}/auth/refresh`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
    method: "POST",
  });
  return response.json();
}

export async function listMeasurements() {
  const response = await fetch(`${API_URL}/v1/measurements`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
  return response.json();
}

export async function getMeasurement({
  measurement,
  time_window = "1d",
  start_date,
  end_date,
  tz = Intl.DateTimeFormat().resolvedOptions().timeZone,
  fill = "previous",
}) {
  const searchParams = new URLSearchParams({
    time_window,
    tz,
    start_date,
    end_date,
    fill,
  });
  const response = await fetch(
    `${API_URL}/v1/measurements/${measurement}?${searchParams.toString()}`,
    {
      headers: {
        Authorization: `Bearer ${getJWT()}`,
      },
    }
  );
  return response.json();
}

export async function getCorrelations({
  measurements,
  method = "pearson",
  time_window = "1d",
  start_date,
  end_date,
  tz = Intl.DateTimeFormat().resolvedOptions().timeZone,
  fill = "previous",
}) {
  const searchParams = new URLSearchParams({
    measurements: measurements.join(","),
    time_window,
    tz,
    start_date,
    end_date,
    fill,
    method,
  });
  const response = await fetch(
    `${API_URL}/v1/correlations?${searchParams.toString()}`,
    {
      headers: {
        Authorization: `Bearer ${getJWT()}`,
      },
    }
  );
  return response.json();
}

export async function getLAS({ identifier }) {
  if (identifier === "RJS-739A_NMR_LOG") {
    return RJS379ANRMLog;
  }

  const response = await fetch(`${API_URL}/v1/las/${identifier}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
  return response.json();
}

export async function getLocationWells() {
  const response = await fetch(`${API_URL}/v1/well_locations`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
  return response.json();
}

export async function computeAnomalies({
  measurement,
  time_window = "1d",
  start_date,
  end_date,
  tz = Intl.DateTimeFormat().resolvedOptions().timeZone,
  fill = "previous",
  n_estimators,
  contamination,
  max_features
}) {
  const searchParams = new URLSearchParams({
    time_window,
    tz,
    start_date,
    end_date,
    fill,
    n_estimators,
    contamination,
    max_features
  });
  const response = await fetch(
    `${API_URL}/v1/anomaly_detection/${measurement}?${searchParams.toString()}`,
    {
      headers: {
        Authorization: `Bearer ${getJWT()}`,
      },
    }
  );
  return response.json();
};

export async function getClassTree() {
  const response = await fetch(`${API_URL}/v1/ontology/class_tree`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
  return response.json();
};

export async function getClassTreeWithCounter(query) {
  const response = await fetch(`${API_URL}/v1/ontology/class_tree?query=${query}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
  return response.json();
};


export async function getInstancesByClass(className) {
  const response = await fetch(`${API_URL}/v1/ontology/instances_of/${className}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
  return response.json();
};

export async function getEntitiesByClass(className) {
  const response = await fetch(`${API_URL}/v1/ontology/entities_information/${className}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
  return response.json();
};

export async function getQualitiesByClass(className) {
  const response = await fetch(`${API_URL}/v1/ontology/qualities_of/${className}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
  return response.json();
};

export async function getComponentsByClass(className) {
  const response = await fetch(`${API_URL}/v1/ontology/components_of/${className}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
  return response.json();
};

export async function getPropertiesByInstance(instanceName) {
  const response = await fetch(`${API_URL}/v1/ontology/quality_classes_of_instance/${instanceName}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
  return response.json();
};

export async function getInformationByProperty(instanceName, propertyName) {
  const response = await fetch(`${API_URL}/v1/ontology/quality_instances_of_class_instance/${instanceName}/${propertyName}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
  return response.json();
};

export async function getInformationEntityAbout(instanceName) {
  const response = await fetch(`${API_URL}/v1/ontology/information_entities_about/${instanceName}`, {
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
  });
  return response.json();
};
