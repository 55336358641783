import React, { useEffect, useMemo, useState } from "react";
import { Container, Dropdown } from "react-bootstrap";
import styled from "styled-components";
import DeepthMetrics from "../components/DepthMetrics";
import { LAS_DATA_LIST } from "../constants";
import { useQuery } from "react-query";
import { getLAS } from "../api";
import Loader from "../components/Loader";
import { startCase, uniq, get } from "lodash-es";
import { Trans, useTranslation } from "react-i18next";

const availableMetrics = ["FF", "PERM", "PHIE", "PHIT", "SWIE"];

const STORAGE_KEY = "petwin.metrics.state";

const getFromStorage = (prop, defaultValue) => () => {
  const data = JSON.parse(localStorage.getItem(STORAGE_KEY));
  return get(data, prop, defaultValue);
};

function Metrics() {
  const [groupBy, setGroupBy] = useState("metric");

  const [selectedDatasets, setSelectedDatasets] = useState(
    getFromStorage("selectedDatasets", ["RJS-739A", "RJS-742A", "RJS-751D"])
  );

  const [t] = useTranslation();

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify({ selectedDatasets }));
  }, [selectedDatasets]);

  const availableDatasets = useMemo(
    () => selectedDatasets.map((x) => LAS_DATA_LIST[x]),
    [selectedDatasets]
  );

  const { data: rawData, isLoading } = useQuery(
    ["las-data", { availableDatasets }],
    async ({ queryKey }) => {
      const [, { availableDatasets }] = queryKey;

      const allDatasets = await Promise.all(
        availableDatasets.map((identifier) => getLAS({ identifier }))
      );

      const groupedByWell = Object.fromEntries(
        allDatasets.map((dataset, index) => {
          const well = availableDatasets[index].replace("_NMR_LOG", "");

          const seriesData = Object.fromEntries(
            availableMetrics.map((metric) => {
              return [
                metric,
                dataset.map((p) => ({
                  depth: p.Depth,
                  value: p[metric],
                })),
              ];
            })
          );

          return [well, seriesData];
        })
      );

      const groupedByMetric = Object.fromEntries(
        availableMetrics.map((metric) => {
          const seriesData = Object.fromEntries(
            allDatasets.map((dataset, index) => {
              const well = availableDatasets[index].replace("_NMR_LOG", "");

              return [
                well,
                dataset.map((p) => ({
                  depth: p.Depth,
                  value: p[metric],
                })),
              ];
            })
          );

          return [metric, seriesData];
        })
      );

      return { groupedByMetric, groupedByWell };
    }
  );

  const data = useMemo(
    () =>
      groupBy === "metric" ? rawData?.groupedByMetric : rawData?.groupedByWell,
    [rawData, groupBy]
  );

  const [maxDepth, minDepth] = useMemo(() => {
    if (!data) return [0, 0];

    let minD = Number.POSITIVE_INFINITY;
    let maxD = Number.NEGATIVE_INFINITY;

    Object.values(data).forEach((groupedData) => {
      Object.values(groupedData).forEach((list) => {
        list.forEach((point) => {
          if (point.depth < minD) {
            minD = point.depth;
          }
          if (point.depth > maxD) {
            maxD = point.depth;
          }
        });
      });
    });

    return [maxD, minD];
  }, [data]);

  if (isLoading || !data) {
    return (
      <div style={{ height: 300 }}>
        <Loader />
      </div>
    );
  }

  return (
    <Container>
      <Header>
        <div>
          <Trans i18nKey="nmr.labels.groupData" />{" "}
          <Dropdown>
            <Dropdown.Toggle variant="light" id="nmr-group-by">
              {startCase(t(`common.labels.${groupBy}`))}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setGroupBy("metric")}>
                <Trans i18nKey="common.labels.metric" />
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setGroupBy("well")}>
                <Trans i18nKey="common.labels.well" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic">
              <Trans i18nKey='nmr.labels.filterData' /> {`(${selectedDatasets.length}) `} <Trans i18nKey='nmr.labels.selected' />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {Object.keys(LAS_DATA_LIST).map((datasetId) => {
                const isActive = selectedDatasets.includes(datasetId);

                return (
                  <Dropdown.Item
                    key={datasetId}
                    active={isActive}
                    onClick={() => {
                      if (isActive) {
                        setSelectedDatasets(
                          selectedDatasets.filter((x) => x !== datasetId)
                        );
                      } else {
                        setSelectedDatasets(
                          uniq([...selectedDatasets, datasetId])
                        );
                      }
                    }}
                  >
                    {datasetId}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Header>
      {Object.entries(data).map(([primary, groupedData]) => (
        <Wrapper key={primary}>
          <Title>{primary}</Title>
          <DeepthMetrics
            isLog={primary === "PERM"}
            series={groupedData}
            maxDepth={maxDepth}
            minDepth={minDepth}
          />
        </Wrapper>
      ))}
    </Container>
  );
}

export default Metrics;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0;

  > div {
    margin-left: 5px;
    display: flex;
    align-items: center;
  }
`;

const Title = styled.h3`
  text-align: center;
  position: relative;

  &::before {
    content: " ";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    right: 65%;
    height: 1px;
    background: #0c0c0c;
  }

  &&::after {
    content: " ";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 65%;
    right: 0px;
    height: 1px;
    background: #0c0c0c;
  }
`;

const Wrapper = styled.div`
  margin: 2em 0 2em;
`;
