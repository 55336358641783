import moment from "moment";
import { useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import { Redirect, Route, Switch } from "react-router";
import { NavLink } from "react-router-dom";
import LogoWhite from "../logo-white.svg";
import Correlations from "../modules/Correlations";
import Dashboard from "../modules/Dashboard";
import OntologyExplorer from "../modules/OntologyExplorer";
import MapExplorer from "../modules/MapExplorer";
import Metrics from "../modules/Metrics";
import Overview from "../modules/Overview";
import { useTranslation, Trans } from "react-i18next";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import "leaflet/dist/leaflet.css";
import { useAuth } from "../providers/auth";

const lngs = {
  en: { languageName: "English" },
  pt: { languageName: "Portuguese" },
};

function Root() {
  const { i18n } = useTranslation();

  const auth = useAuth();

  const [state, setState] = useState({
    startDate: moment(new Date(2018, 0, 1)).startOf("day"),
    endDate: moment(new Date(2019, 9, 12)).endOf("day"),
    focusedInput: null,
  });

  return (
    <main>
      <Navbar
        bg="dark"
        variant="dark"
        expand="lg"
        style={{ minHeight: "80px" }} >
        <Navbar.Brand href="/">
          <img
            alt=""
            src={LogoWhite}
            height="30"
            className="d-inline-block align-top mr-2"
          />{" "}
          PeTWIN
        </Navbar.Brand>
        <Navbar.Collapse>
          <Nav className="mr-auto">
            <NavLink className="nav-link" to="/dashboard">
              <Trans i18nKey="menu.title.dashboard" />
            </NavLink>
            <NavLink className="nav-link" to="/correlations">
              <Trans i18nKey="menu.title.correlation" />
            </NavLink>
            <NavLink className="nav-link" to="/map-explorer">
              <Trans i18nKey="menu.title.mapExplorer" />
            </NavLink>
            <NavLink className="nav-link" to="/nmr">
              <Trans i18nKey="menu.title.nmr" />
            </NavLink>
            <NavLink className="nav-link" to="/cats-and-sasmic">
              <Trans i18nKey="menu.title.catsAndSasmic" />
            </NavLink>
            <NavLink className="nav-link" to="/ontology-explorer">
              <Trans i18nKey="menu.title.ontologyExplorer" />
            </NavLink>
          </Nav>

          <Navbar.Text>
            <Switch>
              <Route path={["/dashboard", "/correlations", "/cats-and-sasmic", "/ontology-explorer"]}>
                <DateRangePicker
                  minimumNights={0}
                  enableOutsideDays
                  startDateId="startDate"
                  endDateId="endDate"
                  startDate={state.startDate}
                  endDate={state.endDate}
                  onDatesChange={({ startDate, endDate }) => {
                    setState((state) => ({
                      ...state,
                      startDate: startDate
                        ? startDate.clone().startOf("days")
                        : startDate,
                      endDate: endDate
                        ? endDate.clone().endOf("days")
                        : endDate,
                    }));
                  }}
                  focusedInput={state.focusedInput}
                  onFocusChange={(focusedInput) => {
                    setState((state) => ({ ...state, focusedInput }));
                  }}
                  isOutsideRange={() => false}
                />
              </Route>
            </Switch>
          </Navbar.Text>
          <div className="ml-3">
            <ContextMenuTrigger id="languages">
              <span style={{ fontSize: 20, color: 'white' }}>
                {i18n.language === "pt" ? "BR" : "EN"}
              </span>
            </ContextMenuTrigger>
            <ContextMenu
              style={{
                backgroundColor: 'rgba(85, 144, 144, .75)',
                width: "200px",
                padding: "20px",
                position: "absolute",
                zIndex: "1",
                marginTop: "30px",
                marginLeft: "20px",
                alignItems: "initial"
              }}
              id="languages" >
              {Object.keys(lngs).map((lng) => (
                <MenuItem key={lng} onClick={() => i18n.changeLanguage(lng)}>
                  <span style={{ display: 'block', fontSize: '18px', paddingTop: 10, paddingBottom: 10 }}>
                    <strong> {lngs[lng].languageName}</strong>
                  </span>
                </MenuItem>
              ))}
            </ContextMenu>
          </div>
          <Nav>
            <NavDropdown
              title=""
              className="nav-link"
              id="collasible-nav-dropdown"
              alignRight
            >
              <NavDropdown.Item
                onClick={() => {
                  auth.signout(() => {
                    window.location.pathname = "/login";
                  });
                }}
              >
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Switch>
        <Route path="/dashboard">
          <Dashboard startDate={state.startDate} endDate={state.endDate} />
        </Route>
        <Route path="/cats-and-sasmic">
          <Overview startDate={state.startDate} endDate={state.endDate} />
        </Route>
        <Route path="/correlations">
          <Correlations startDate={state.startDate} endDate={state.endDate} />
        </Route>
        <Route path="/map-explorer">
          <MapExplorer />
        </Route>
        <Route path="/nmr">
          <Metrics />
        </Route>
        <Route path="/ontology-explorer">
          <OntologyExplorer startDate={state.startDate} endDate={state.endDate} />
        </Route>
        <Route>
          <Redirect to="/dashboard" />
        </Route>
      </Switch>
    </main>
  );
}

export default Root;
