import { useQuery } from "react-query";
import { useMemo, useContext } from "react";
import Loader from "./Loader";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { startCase } from "lodash";
import { getPropertiesByInstance } from '../api';
import * as React from 'react';
import { OntologyExplorerContext } from "../modules/OntologyExplorer";

function PropertiesEntities({ component, tags, setTags }) {

    const { updateDashboard, informationStore } = useContext(OntologyExplorerContext)
    const { data, isLoading } = useQuery(["propertiesByInstance", component.base64], () => getPropertiesByInstance(component.base64))

    const properties = useMemo(() => {
        if (!isLoading) {
            return Object.values(data.classes).map((property) => {
                return {
                    component: component.base64, component_name: component.component, property: property.class_name, base64: property.class_base64_iri,
                    checked: informationStore.cache[`${component.component}/${property.class_name}`] !== undefined
                }
            })
        }
    }, [isLoading, data, component, informationStore.cache])

    const handleCheck = (event) => {

        if (!event.target.checked) {
            delete informationStore.cache[`${component.component}/${event.target.name}`]
        }

        let newData = { ...tags };

        if (!newData[component.component]) {
            properties.forEach(p => {
                if (p.component === component.base64 && p.property === event.target.name) {
                    p.checked = event.target.checked
                }
            })
            newData[component.component] = properties;
            setTags(newData)
        }

        else {
            Object.entries(newData).forEach(([key, values]) => {
                if (key === component.component) {
                    values.forEach(k => {
                        if (k.component === component.base64 && k.property === event.target.name) {
                            k.checked = event.target.checked
                        }
                    })
                }
            })
            setTags(newData)
        }

        updateDashboard(newData)

    };

    return (
        <div>
            {isLoading ? <Loader /> :
                <FormGroup>
                    {
                        properties.length > 0 ?
                            properties.flatMap((p) => {
                                return (
                                    <FormControlLabel key={p.property} control={
                                        <Checkbox onChange={handleCheck}
                                            name={p.property} value={p.component}
                                            defaultChecked={p.checked} inputProps={{ 'aria-label': 'controlled' }} />
                                    }
                                        label={startCase(p.property)} />
                                )
                            })
                            : "This tag hasn't retrieve properties information"
                    }
                </FormGroup>
            }
        </div>
    )
}

export default PropertiesEntities;