import { keyBy, orderBy, startCase, get } from "lodash";
import React, { useMemo, useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { listMeasurements } from "../api";
import CorrelationMatrix from "../components/CorrelationMatrix";
import Loader from "../components/Loader";
import { TAG_BY_ID } from "../constants";
import { Multiselect } from "multiselect-react-dropdown";
import { Trans, useTranslation } from 'react-i18next';

const STORAGE_KEY = "petwin.correlations.form";

const correlation_methods = ["pearson", "kendall", "spearman"];

const getFromStorage = (prop, defaultValue) => () => {
  const data = JSON.parse(localStorage.getItem(STORAGE_KEY));
  return get(data, prop, defaultValue);
};

const getOptions = ({ method, selectedTags }) => () => {
  const measurements = Object.keys(selectedTags).filter(
    (tagId) => selectedTags[tagId]
  );
  return {
    method,
    measurements,
  };
};

function Correlations({ startDate, endDate }) {
  const [method, setMethod] = useState(
    getFromStorage("method", correlation_methods[0])
  );
  const [selectedTags, setSelectedTags] = useState(
    getFromStorage("selectedTags", {})
  );
  const [options, setOptions] = useState(getOptions({ method, selectedTags }));

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify({ method, selectedTags }));
  }, [selectedTags, method]);

  const { data: measurementData = [], isLoading } = useQuery(
    "measurements",
    () => listMeasurements()
  );

  const [t] = useTranslation();

  const availableTagsById = useMemo(() => {
    return keyBy(
      measurementData,
      "name"
    );
  }, [measurementData]);

  const selectOptions = useMemo(() => {
    return orderBy(
      Object.keys(availableTagsById).map((tagId) => {
        const tag = TAG_BY_ID[tagId];

        if (!tag) {
          return {
            well: 'Uncategorized',
            description: tagId,
            label: tagId,
            type: 'other',
            id: tagId
          }
        }

        return {
          ...tag,
          label: `${tag.description} (${tag.well})`,
        };
      }),
      "well"
    );
  }, [availableTagsById]);

  const effectiveOptions = useMemo(() => {
    const { measurements = [], ...rest } = options;
    return {
      ...rest,
      measurements:
        measurements.length > 0 ? measurements : Object.keys(availableTagsById).filter(tagId => TAG_BY_ID[tagId]),
    };
  }, [options, availableTagsById]);

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <Loader />
      </div>
    );
  }

  return (
    <Container fluid className="mt-2">
      <Row>
        <Col md={3}>
          <Card>
            <Card.Header><Trans i18nKey="correlationForm.title.options" /></Card.Header>
            <Card.Body>
              <Form
                onReset={() => {
                  setOptions({});
                  setMethod(correlation_methods[0]);
                  setSelectedTags({});
                }}
                onSubmit={(event) => {
                  event.preventDefault();

                  setOptions(getOptions({ method, selectedTags }));
                }}
              >
                <Form.Group>
                  <Form.Label><Trans i18nKey="correlationForm.labels.selectedTags" /></Form.Label>
                  <Multiselect
                    options={selectOptions}
                    displayValue="label"
                    groupBy="well"
                    closeIcon="cancel"
                    selectedValues={selectOptions.filter(
                      ({ id: tagId }) => selectedTags[tagId]
                    )}
                    onSelect={(_, item) => {
                      setSelectedTags((current) => ({
                        ...current,
                        [item.id]: true,
                      }));
                    }}
                    onRemove={(_, item) => {
                      setSelectedTags((current) => ({
                        ...current,
                        [item.id]: false,
                      }));
                    }}
                    placeholder={t('correlationForm.labels.searchTags')}
                    closeOnSelect={false}
                    hidePlaceholder
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label><Trans i18nKey="correlationForm.labels.correlationMethod" /></Form.Label>
                  <Form.Control
                    value={method}
                    onChange={(event) => {
                      const { value } = event.target;
                      setMethod(value);
                    }}
                    as="select"
                  >
                    {correlation_methods.map((value) => (
                      <option key={value} value={value}>
                        {startCase(value)}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <div className="d-flex justify-content-between">
                  <Button variant="secondary" type="reset" className="ml-2">
                    <Trans i18nKey="common.components.resetButton" />
                  </Button>
                  <Button variant="primary" type="submit">
                    <Trans i18nKey="common.components.applyButton" />
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <CorrelationMatrix
            {...effectiveOptions}
            startDate={startDate}
            endDate={endDate}
          />
        </Col>
      </Row>
    </Container >
  );
}

export default Correlations;
