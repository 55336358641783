import React from "react";
import { castArray } from "lodash";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import overViewTags from "../assets/overviewcards.json"
import OverViewCardChart from "../components/OverViewCardChart";

function Overview({ startDate, endDate }) {

  return (
    <Grid className="m-4">
      {
        overViewTags.flatMap(tags => {
          return (
            <Card>
              <Card.Body>
                <Card.Title>{tags.title}</Card.Title>
                {castArray(tags.measurements).map((key, index, arr) => {
                  const [measurement, legend] = key.split(",");
                  return (
                    <ChartWrapper key={measurement}>
                      {arr.length > 1 && <h5 className="text-muted">{legend}</h5>}
                      <OverViewCardChart
                        title={legend || tags.title}
                        measurement={measurement}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </ChartWrapper>
                  );
                })}
              </Card.Body>
            </Card>
          )
        })
      }
    </Grid>
  );
}

export default Overview;

const Grid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
`;

const ChartWrapper = styled.div`
  h5 {
    font-size: 1rem;
  }

  & + & {
    margin-top: 10px;
  }
`;
