import * as d3 from "d3";
import { first, last, mean, sumBy } from "lodash";
import React, { useMemo } from "react";
import { Badge } from "react-bootstrap";
import { useQuery } from "react-query";
import styled from "styled-components";
import { getMeasurement } from "../api";
import { getTimeWindow } from "../utils/functions";
import Loader from "./Loader";
import MeasurementSimpleChart from "./MeasurementSimpleChart";
import { Trans } from 'react-i18next';

const numberFormatter = d3.format(",.2~f");

function MeasurementMultipleLine({ tag, startDate, endDate }) {

  const { data = [], isLoading } = useQuery(
    startDate &&
    endDate && [
      "measurement",
      {
        measurements: tag.children,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        time_window: getTimeWindow(startDate, endDate),
        fill: "none",
      },
    ],
    async ({ queryKey }) => {
      const [, params] = queryKey;

      const { measurements, ...rest } = params;
      const dataList = await Promise.all(
        measurements.map((measurement) =>
          getMeasurement({ ...rest, measurement })
        )
      );

      const effectiveData = first(dataList).map((item, index) => {
        const { time } = item;

        return {
          time,
          mean: sumBy(dataList, (x) => x[index]?.mean),
        };
      });

      return effectiveData;
    }
  );

  const meanValue = useMemo(() => mean(data.map((x) => x.mean)), [data]);

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div style={{ width: "15%" }}>
        <h3><Trans i18nKey='dashboards.labels.oilProduced'/></h3>
        <div>
          <Badge variant="light">
            <h5><Trans i18nKey="common.labels.badgeAvg" />{" "}</h5>
            <h4>
              {isLoading || data.length === 0
                ? "–"
                : numberFormatter(meanValue)}
            </h4>
          </Badge>
          <Badge className="ml-2" variant="light">
            <h5><Trans i18nKey="common.labels.badgeLast" />{" "}</h5>
            <h4>
              {isLoading || data.length === 0
                ? "–"
                : numberFormatter(last(data).mean)}
            </h4>
          </Badge>
        </div>
      </div>
      <div className={isLoading ? 'd-flex align-items-center justify-content-center': null} style={{ flexShrink: false, width: "85%", height: 250 }}>
        {isLoading ? (
          <Loader width="120" />
        ) : (
          data.length > 0 && (
            <ChartWrapper>
              <MeasurementSimpleChart
                data={data}
                tag={tag}
                startDate={startDate}
                endDate={endDate}
              />
            </ChartWrapper>
          )
        )}
      </div>
    </div>
  );
}

export default MeasurementMultipleLine;


const ChartWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
`;