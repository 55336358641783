import { useMemo, useContext, useState } from "react";
import { Badge, ListGroup, Row } from "react-bootstrap";
import MeasurementLine from "../components/MeasurementLine";
import { Trans } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { WELLS_BY_TYPE, WELLS } from "../constants";
import { keyBy, startCase, groupBy } from "lodash";
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OntologyExplorerContext } from "../modules/OntologyExplorer";
import DeleteIcon from '@mui/icons-material/Delete';
import { colorHash } from "../utils/functions";

function OntologyDashboard() {

    const { startDate, endDate, data, cdata, updateDashboard, dashboard, removeCache } = useContext(OntologyExplorerContext)

    const [panelExpanded, setPanelExpanded] = useState(
        groupBy(Object.entries(WELLS).flatMap(([key, entries]) => {
            return { well: key, expanded: true }
        }), "well")
    )

    let wellTypeValidation = {}
    let wellValidation = {}


    const changePanel = (panel) => (event, isExpanded) => {
        setPanelExpanded((data) =>
            groupBy(Object.entries(data).flatMap(([key, entries]) => {
                if (key === panel) {
                    return { well: key, expanded: isExpanded }
                }
                else {
                    return { well: key, expanded: panelExpanded[key][0].expanded }
                }
            }), "well")
        )
    }

    const isTagAvailable = useMemo(() => {
        const existingData = keyBy(data, "name");
        return {
            ...existingData
        };
    }, [data]);



    const handleDelete = (tag) => () => {

        removeCache(`${tag.component}/${tag.property}`)

        updateDashboard(groupBy(Object.entries(dashboard).flatMap(([key, entries]) =>
            entries.filter(e => !(e.component === tag.component && e.property === tag.property))
        ), "component"))

    };

    const validateTypeWell = (type, name) => {
        Object.entries(cdata).filter(([well]) => WELLS[well].type === type).forEach(([well, tags]) => {
            name.forEach(w => {
                if (w.name === well) {
                    tags.forEach(tag => {
                        if (isTagAvailable[tag.id]) {
                            wellTypeValidation[type] = true
                        }
                    })
                }
            })
        })
    }

    const validateWell = () => {
        Object.entries(cdata).forEach(([well, tags]) => {
            tags.forEach(tag => {
                if (isTagAvailable[tag.id]) {
                    wellValidation[well] = true
                }
            })
        })
    }

    /*=================================*/
    /* BEGIN VISUALIZATION COMPONENTS  */
    /*===============================*=*/

    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': { borderBottom: 0, },
        '&:before': { display: 'none', },
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon />} {...props} />
    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark' ?
                'rgba(255, 255, 255, .5)' :
                'rgba(85, 144, 144, .1)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));

    /*=================================*/
    /*  END VISUALIZATION COMPONENTS   */
    /*===============================*=*/
    return (
        <div>
            {
                dashboard === "all" ? [] :
                    <Row className={`m-" + ${12 / Object.entries(WELLS_BY_TYPE).length}`} style={{ height: '100%', flexGrow: 1, maxWidth: '100%', padding: '5px' }}>
                        {
                            Object.entries(dashboard).flatMap(([key, entries]) =>
                                entries.filter(e => e.checked).map(e => {
                                    return (
                                        <Chip label={startCase(e.component_name) + " - " + startCase(e.property)}
                                            onDelete={handleDelete(e)}
                                            style={{
                                                margin: '5px', fontSize: '18px',
                                                backgroundColor: `rgb(${colorHash(e.component + e.component).r},${colorHash(e.component + e.component).g},${colorHash(e.component + e.component).b},0.1)`
                                            }}
                                            variant="outlined"
                                            deleteIcon={<DeleteIcon />} />
                                    )
                                })
                            )}
                    </Row>
            }
            <Row className={`m-" + ${12 / Object.entries(WELLS_BY_TYPE).length}`} style={{ height: '100%', flexGrow: 1, maxWidth: '100%' }}>
                {Object.entries(WELLS_BY_TYPE).map(([type, name]) => {
                    validateTypeWell(type, name)
                    if (wellTypeValidation[type]) {
                        return (
                            <div className="panel panel-default" key={type}>
                                <div className="panel-heading">
                                    <h2 className="panel-title d-flex align-items-center">
                                        <div className="ml-8">
                                            <Badge pill variant="dark"> {startCase(type)}</Badge>
                                        </div>
                                    </h2>
                                </div>
                                <div className="panel-body" style={{ margin: '2px' }}>
                                    {Object.entries(cdata).filter(([well]) => WELLS[well].type === type).map(([well, tags]) => {
                                        validateWell()
                                        if (wellValidation[well]) {
                                            return (
                                                <div key={`container-${well}`}>
                                                    {/* <Accordion style={{ 'marginTop': '5px' }} expanded={panelExpanded[well]} onChanged={changePanel(well)} > */}
                                                    <Accordion style={{ 'marginTop': '5px' }} key={`accordion-${well}`} expanded={panelExpanded[well][0].expanded} onChange={changePanel(well)}>
                                                        <AccordionSummary
                                                            key={`summary-${well}`}
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <h3 className="panel-title d-flex align-items-center">
                                                                <Trans i18nKey="common.labels.well" /> {well}{" "}
                                                            </h3>
                                                        </AccordionSummary>
                                                        <AccordionDetails key={`detail-${well}`}>
                                                            <Typography>
                                                                <ListGroup variant="flush">
                                                                    {tags
                                                                        .filter((t) => t.id !== "OIL_PRODUCED")
                                                                        .flatMap((tag) => {
                                                                            return isTagAvailable[tag.id] ? (
                                                                                <ListGroup.Item key={tag.id}>{
                                                                                    <MeasurementLine
                                                                                        key={tag.id}
                                                                                        measurementData={data}
                                                                                        tag={tag}
                                                                                        startDate={startDate}
                                                                                        endDate={endDate}
                                                                                    />}
                                                                                </ListGroup.Item>
                                                                            ) : ([]);
                                                                        })}
                                                                </ListGroup>
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            )
                                        }
                                        else return ([])
                                    })}
                                </div>
                            </div>)
                    }
                    else {
                        return ([])
                    }
                })}
            </Row>
        </div>
    )
}
export default OntologyDashboard;