import Highcharts from "highcharts"
import more from "highcharts/highcharts-more";
import { getMeasurement } from "../api";
import Loader from "../components/Loader";
import styled from "styled-components";
import { useMemo, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import { colorHash, getTimeWindow } from "../utils/functions";
import { Trans, useTranslation } from 'react-i18next';
import { Multiselect } from 'multiselect-react-dropdown';
import { Button, Modal } from "react-bootstrap";
import { useQuery } from "react-query";
import overViewTags from "../assets/overviewcards.json"
import MeasurementLineHighchart from "./MeasurementLineHighchart";

more(Highcharts);

function OverviewCardChart({ title, startDate, endDate, measurement }) {

    const shouldFecth = startDate && endDate;
    const [t] = useTranslation();
    const opts = []
    const [showModal, setShowModal] = useState(false);
    const [cdata, setCdata] = useState([])
    const { data, status } = useQuery(
        shouldFecth && [
            "measurement",
            {
                measurement,
                start_date: startDate.toISOString(),
                end_date: endDate.toISOString(),
                time_window: getTimeWindow(startDate, endDate),
                fill: "previous",
            },
        ],
        ({ queryKey }) => {
            const [, params] = queryKey;
            return getMeasurement(params);
        }
    );

    const handleClose = () => setShowModal(false);
    const handleShow = () => {
        setCdata(() => [{
            tag: measurement,
            data: data,
            title: title
        }]
        )
        setShowModal(true)
    };

    localStorage.setItem(JSON.stringify(measurement), JSON.stringify(data))

    overViewTags.forEach((tag) => {
        if (tag.measurements.length > 1) {
            const measurements = tag.measurements.filter((m) => m.split(",")[1] !== title);
            measurements.forEach((measurement) => {
                opts.push({
                    id: tag.title + '|' + measurement.split(",")[1],
                    label: tag.title + ' - ' + measurement.split(",")[1],
                    measurement: measurement.split(",")[0]
                })
            })
        }
        else {
            if (!tag.title.startsWith(title)) {
                opts.push({
                    id: tag.title,
                    label: tag.title,
                    measurement: tag.measurements[0].split(",")[0]
                })
            }
        }
    })

    const onSelect = (selectedList, selectedItem) => {
        setCdata((cdata) => [
            ...cdata,
            {
                tag: selectedItem.measurement,
                data: JSON.parse(localStorage.getItem(JSON.stringify(selectedItem.measurement))),
                title: selectedItem.id
            }
        ])
    }

    const onRemove = (selectedList, removedItem) => {
        setCdata(cdata.filter(c =>
            !(c.tag === removedItem.measurement)
        ))
    }

    const chartOptions = useMemo(() => {

        const ranges = data?.map(({ max, min, time }) => [time, min, max]);
        const averages = data?.map(({ mean, time }) => [time, mean]);
        const { hex: color } = colorHash(title);

        return {
            chart: {
                height: "200px",
            },

            title: {
                text: null,
            },

            xAxis: {
                type: "datetime",
                // accessibility: {
                //   rangeDescription: "Range: Jul 1st 2009 to Jul 31st 2009.",
                // },
            },

            yAxis: {
                title: {
                    text: null,
                },
            },

            tooltip: {
                crosshairs: true,
                shared: true,
                // valueSuffix: "°C",
            },

            legend: {
                enabled: false,
            },

            credits: {
                enabled: false,
            },

            series: [
                {
                    name: title,
                    data: averages,
                    zIndex: 1,
                    marker: {
                        fillColor: "white",
                        lineWidth: 2,
                        lineColor: color,
                    },
                    color: color,
                },
                {
                    name: "Range",
                    data: ranges,
                    type: "arearange",
                    lineWidth: 0,
                    linkedTo: ":previous",
                    color: color,
                    fillOpacity: 0.3,
                    zIndex: 0,
                    marker: {
                        enabled: false,
                    },
                },
            ],
        };
    }, [data, title]);

    return (
        <div>
            <ChartContainer onClick={status === 'loading' ? null : handleShow}>
                {status === "loading" ? (
                    <Loader />
                ) : (
                    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                )}
            </ChartContainer>

            <Modal
                show={showModal}
                onHide={handleClose}
                size="lg"
                dialogAs={ModalDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mb-2 md-2" >
                    <div className="mb-2" style={{ height: "50vh" }}>
                        <MeasurementLineHighchart
                            onClick
                            data={cdata} isCard={true} />
                    </div>
                    <div>
                        <Multiselect
                            placeholder={t('modal.dashboards.compareWith')}
                            options={opts}
                            displayValue="label"
                            groupBy="well"
                            onSelect={onSelect}
                            closeIcon='cancel'
                            onRemove={onRemove}
                            showCheckbox={true}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="mt-2">
                    <Button variant="secondary" onClick={handleClose}>
                        <Trans i18nKey="common.components.backButton" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default OverviewCardChart;

const ChartContainer = styled.div`
  height: 200px;
`;

const ModalDialog = styled(Modal.Dialog)`
  width: 80vw;
  max-width: unset !important;
`;