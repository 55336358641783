import { flatMap, groupBy, keyBy, uniq, startCase } from "lodash";
import tags739 from "./tags/739.json";
import tags742 from "./tags/742.json";
import tags751 from "./tags/751.json";
import tags743 from "./tags/743.json";
import tags731 from "./tags/731.json";
import tags744 from "./tags/744.json";
import tags745 from "./tags/745.json";
import tags747 from "./tags/747.json";
import tags748 from "./tags/748.json";
import tags751Obs from "./tags/751-obs.json";

const tagMapper = (well) => (item) => {
  const description = startCase(item["Tag Description"]);
  const type = item["Type"];
  const unit = item["Units"];
  const id = item["PI Tag (main)"];
  const secondaryId = item["PI Tag (backup)"];

  const tagConfig = {
    well,
    description,
    type,
    unit,
    id,
  };

  if (secondaryId) {
    return [{ ...tagConfig, "id": secondaryId }, tagConfig];
  }

  return tagConfig;
};

export const OIL_PRODUCED_TAG = {
  "id": "OIL_PRODUCED",
  children: [
    "FPSO-PL_20_FIT_0501_CVOL",
    "FPSO-PL_20_FIT_0511_CVOL",
    "FPSO-PL_20_FIT_0521_CVOL",
    "FPSO-PL_20_FIT_0531_CVOL",
    "FPSO-PL_20_FIT_0541_CVOL",
  ],
  description: "Oil produced",
  well: "RJS-739",
  type: "Counter",
  unit: "-",
};

export const TAGS = [
  OIL_PRODUCED_TAG,
  ...flatMap(tags739, tagMapper("RJS-739")),
  ...flatMap(tags742, tagMapper("RJS-742")),
  ...flatMap(tags751, tagMapper("RJS-751-INJ")),
  ...flatMap(tags743, tagMapper("RJS-743")),
  ...flatMap(tags731, tagMapper("RJS-731")),
  ...flatMap(tags744, tagMapper("RJS-744")),
  ...flatMap(tags745, tagMapper("RJS-745")),
  ...flatMap(tags747, tagMapper("RJS-747")),
  ...flatMap(tags748, tagMapper("RJS-748")),
  ...flatMap(tags751Obs, tagMapper("RJS-751-OBS")),
];

export const WELLS = {
  'RJS-739': {
    type: "producer",
    name: 'RJS-739'
  },
  'RJS-742': {
    type: "injector",
    name: 'RJS-742'
  },
  'RJS-751-INJ': {
    type: "injector",
    name: 'RJS-751-INJ'
  },
  'RJS-743': {
    type: "observer",
    name: 'RJS-743'
  },
  'RJS-731': {
    type: "observer",
    name: 'RJS-731'
  },
  'RJS-744': {
    type: "observer",
    name: 'RJS-744'
  },
  'RJS-745': {
    type: "observer",
    name: 'RJS-745'
  },
  'RJS-747': {
    type: "observer",
    name: 'RJS-747'
  },
  'RJS-748': {
    type: "observer",
    name: 'RJS-748'
  },
  'RJS-751-OBS': {
    type: "observer",
    name: 'RJS-751-OBS'
  },
};

export const TAGS_BY_WELL = groupBy(TAGS, "well");

export const WELLS_BY_TYPE = groupBy(WELLS, "type");

export const TAG_BY_ID = keyBy(TAGS, "id");

const colors = [
  "#9467bd",
  "#17becf",
  "#d62728",
  "#e377c2",
  "#2ca02c",
  "#1f77b4",
  "#ff7f0e",
  "#bcbd22",
  "#8c564b",
  "#7f7f7f",
];

export const TAG_TYPE_COLORS = Object.fromEntries(
  uniq(TAGS.map((x) => x.type)).map((type, index) => {
    return [type, colors[index % colors.length]];
  })
);

export const LAS_DATA_LIST = {
  "ANP-2A": "ANP-2A_NMR_LOG",
  "MRO-1": "MRO-1_NMR_LOG",
  "MRO-2A": "MRO-2A_NMR_LOG",
  "MRO-3": "MRO-3_NMR_LOG",
  "MRO-5": "MRO-5_NMR_LOG",
  "RJS-731": "RJS-731_NMR_LOG",
  "RJS-739A": "RJS-739A_NMR_LOG",
  "RJS-741": "RJS-741_NMR_LOG",
  "RJS-742A": "RJS-742A_NMR_LOG",
  "RJS-743A": "RJS-743A_NMR_LOG",
  "RJS-744": "RJS-744_NMR_LOG",
  "RJS-745": "RJS-745_NMR_LOG",
  "RJS-747": "RJS-747_NMR_LOG",
  "RJS-748D": "RJS-748D_NMR_LOG",
  "RJS-749D": "RJS-749D_NMR_LOG",
  "RJS-751D": "RJS-751D_NMR_LOG",
};

export const MEASUREMENT_TAGS = [
  {
    "text": "Well",
    "id": "well",
    "child":
      [
        {
          "text": "Production well",
          "id": "production_well",
          "child": [
            {
              "text": "RJS-739",
              "id": "rjs_739",
              "child": [
                {
                  "text": "Tubing",
                  "id": "tubing",
                  "child": [
                    {
                      "text": "Pressure",
                      "id": "pressure",
                      "child": [
                        {
                          "text": "Tubing downhole pressure",
                          "id": "tubing_downhole_pressure",
                          "child": [
                            {
                              "text": "Poco_RJS_739_SCM_1_IWIS_MA4_P",
                              "id": "Poco_RJS_739_SCM_1_IWIS_MA4_P",
                              "child": []
                            }
                          ]
                        },
                        {
                          "text": "Tubing downhole pressure Filtered",
                          "id": "tubing_downhole_pressure  Filtered",
                          "child": [
                            {
                              "text": "Poco_RJS_739_TubingDP",
                              "id": "Poco_RJS_739_TubingDP",
                              "child": []
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "text": "Temperature",
                      "id": "temperature",
                      "child": [
                        {
                          "text": "Tubing downhole temperature",
                          "id": "tubing_downhole_temperature",
                          "child": [
                            {
                              "text": "Poco_RJS_739_SCM_1_IWIS_MA4_T",
                              "id": "Poco_RJS_739_SCM_1_IWIS_MA4_T",
                              "child": []
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "text": "Valves",
                  "id": "valves",
                  "child": [
                    {
                      "text": "Choke Valve",
                      "id": "choke_valve",
                      "child": [
                        {
                          "text": "Position",
                          "id": "position",
                          "child": [
                            {
                              "text": "FPSOPL_13_MOV_5070",
                              "id": "FPSOPL_13_MOV_5070",
                              "child": []
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "text": "ICV",
                      "id": "icv",
                      "child": [
                        {
                          "text": "Top ICV",
                          "id": "top_icv",
                          "child": [
                            {
                              "text": "Pressure",
                              "id": "pressure",
                              "child": [
                                {
                                  "text": "Top ICV delta pressure",
                                  "id": "top_icv_delta_pressure",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_739_ICV_TopDP",
                                      "id": "Poco_RJS_739_ICV_TopDP",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "text": "Bottom ICV",
                          "id": "bottom_icv",
                          "child": [
                            {
                              "text": "Pressure",
                              "id": "pressure",
                              "child": [
                                {
                                  "text": "Bottom ICV delta pressure",
                                  "id": "bottom_icv_delta_pressure",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_739_ICV_BottomDP",
                                      "id": "Poco_RJS_739_ICV_BottomDP",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "text": "Temperature",
                              "id": "temperature",
                              "child": [
                                {
                                  "text": "Bottom ICV annular temperature",
                                  "id": "bottom_icv_annular_temperature",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_739_SCM_1_IWIS_MA2_T",
                                      "id": "Poco_RJS_739_SCM_1_IWIS_MA2_T",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "text": "Wellhead",
                  "id": "wellhead",
                  "child": [
                    {
                      "text": "Pressure",
                      "id": "pressure",
                      "child": [
                        {
                          "text": "Poco_RJS_739_SCM_1_MA_37",
                          "id": "Poco_RJS_739_SCM_1_MA_37",
                          "child": []
                        }
                      ]
                    },
                    {
                      "text": "Temperature",
                      "id": "temperature",
                      "child": [
                        {
                          "text": "Poco_RJS_739_SCM_1_MA_36",
                          "id": "Poco_RJS_739_SCM_1_MA_36",
                          "child": []
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "text": "Injection well",
          "id": "injection_well",
          "child": [
            {
              "text": "RJS_742",
              "id": "RJS_742",
              "child": [
                {
                  "text": "Tubing",
                  "id": "Tubing",
                  "child": [
                    {
                      "text": "Pressure",
                      "id": "Pressure",
                      "child": [
                        {
                          "text": "Tubing downhole pressure",
                          "id": "Tubing downhole pressure",
                          "child": [
                            {
                              "text": "Poco_RJS_742_SCM_3_IWIS_MA4_P",
                              "id": "Poco_RJS_742_SCM_3_IWIS_MA4_P",
                              "child": []
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "text": "Temperature",
                      "id": "Temperature",
                      "child": [
                        {
                          "text": "Tubing downhole temperature",
                          "id": "Tubing downhole temperature",
                          "child": [
                            {
                              "text": "Poco_RJS_742_SCM_3_IWIS_MA4_T",
                              "id": "Poco_RJS_742_SCM_3_IWIS_MA4_T",
                              "child": []
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "text": "Valves",
                  "id": "valves",
                  "child": [
                    {
                      "text": "ICV",
                      "id": "icv",
                      "child": [
                        {
                          "text": "Top ICV",
                          "id": "top_icv",
                          "child": [
                            {
                              "text": "Pressure",
                              "id": "pressure",
                              "child": [
                                {
                                  "text": "Annular pressure",
                                  "id": "annular_pressure",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_742_SCM_3_IWIS_MA3_P",
                                      "id": "Poco_RJS_742_SCM_3_IWIS_MA3_P",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "text": "Temperature",
                              "id": "temperature",
                              "child": [
                                {
                                  "text": "Annular temperature",
                                  "id": "annular_temperature",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_742_SCM_3_IWIS_MA3_T",
                                      "id": "Poco_RJS_742_SCM_3_IWIS_MA3_T",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "text": "Middle ICV",
                          "id": "icv",
                          "child": [
                            {
                              "text": "Pressure ",
                              "id": "pressure",
                              "child": [
                                {
                                  "text": "Annular pressure",
                                  "id": "annular_pressure",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_742_SCM_3_IWIS_MA2_P",
                                      "id": "Poco_RJS_742_SCM_3_IWIS_MA2_P",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "text": "Temperature",
                              "id": "temperature",
                              "child": [
                                {
                                  "text": "Annular temperature",
                                  "id": "annular_temperature",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_742_SCM_3_IWIS_MA2_T",
                                      "id": "Poco_RJS_742_SCM_3_IWIS_MA2_T",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "text": "Bottom ICV",
                          "id": "bottom_icv",
                          "child": [
                            {
                              "text": "Pressure",
                              "id": "pressure",
                              "child": [
                                {
                                  "text": "Annular pressure",
                                  "id": "annular_pressure",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_742_SCM_3_IWIS_MA1_P",
                                      "id": "Poco_RJS_742_SCM_3_IWIS_MA1_P",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "text": "Temperature",
                              "id": "temperature",
                              "child": [
                                {
                                  "text": "Annular temperature",
                                  "id": "annular_temperature",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_742_SCM_3_IWIS_MA1_T",
                                      "id": "Poco_RJS_742_SCM_3_IWIS_MA1_T",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "text": "Wellhead",
                  "id": "wellhead",
                  "child": [
                    {
                      "text": "Pressure",
                      "id": "pressure",
                      "child": [
                        {
                          "text": "Poco_RJS_742_SCM_3_MA_35",
                          "id": "Poco_RJS_742_SCM_3_MA_35",
                          "child": []
                        }
                      ]
                    },
                    {
                      "text": "Temperature",
                      "id": "temperature",
                      "child": [
                        {
                          "text": "Poco_RJS_742_SCM_3_MA_36",
                          "id": "Poco_RJS_742_SCM_3_MA_36",
                          "child": []
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "text": "RJS_751",
              "id": "rjs_751",
              "child": [
                {
                  "text": "Tubing",
                  "id": "tubing",
                  "child": [
                    {
                      "text": "Pressure",
                      "id": "pressure",
                      "child": [
                        {
                          "text": "Tubing downhole pressure",
                          "id": "tubing_downhole_pressure",
                          "child": [
                            {
                              "text": "Poco_RJS_751_SCM_3_IWIS_MA1_P",
                              "id": "Poco_RJS_751_SCM_3_IWIS_MA1_P",
                              "child": []
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "text": "Temperature",
                      "id": "temperature",
                      "child": [
                        {
                          "text": "Tubing downhole temperature",
                          "id": "tubing_downhole_temperature",
                          "child": [
                            {
                              "text": "Poco_RJS_751_SCM_3_IWIS_MA1_T",
                              "id": "Poco_RJS_751_SCM_3_IWIS_MA1_T",
                              "child": []
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "text": "Valves",
                  "id": "valves",
                  "child": [
                    {
                      "text": "ICV",
                      "id": "icv",
                      "child": [
                        {
                          "text": "Top ICV",
                          "id": "top_icv",
                          "child": [
                            {
                              "text": "Pressure",
                              "id": "pressure",
                              "child": [
                                {
                                  "text": "Annular pressure",
                                  "id": "annular_pressure",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_751_SCM_3_IWIS_MA2_P",
                                      "id": "Poco_RJS_751_SCM_3_IWIS_MA2_P",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "text": "Temperature",
                              "id": "temperature",
                              "child": [
                                {
                                  "text": "Annular temperature",
                                  "id": "annular_temperature",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_751_SCM_3_IWIS_MA2_T",
                                      "id": "Poco_RJS_751_SCM_3_IWIS_MA2_T",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "text": "Middle ICV",
                          "id": "middle_icv",
                          "child": [
                            {
                              "text": "Pressure",
                              "id": "pressure",
                              "child": [
                                {
                                  "text": "Annular pressure",
                                  "id": "annular_pressure",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_751_SCM_3_IWIS_MA3_P",
                                      "id": "Poco_RJS_751_SCM_3_IWIS_MA3_P",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "text": "Temperature",
                              "id": "temperature",
                              "child": [
                                {
                                  "text": "Annular temperature",
                                  "id": "annular_temperature",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_751_SCM_3_IWIS_MA3_T",
                                      "id": "Poco_RJS_751_SCM_3_IWIS_MA3_T",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "text": "Bottom ICV",
                          "id": "bottom_icv",
                          "child": [
                            {
                              "text": "Pressure",
                              "id": "pressure",
                              "child": [
                                {
                                  "text": "Annular pressure",
                                  "id": "annular_pressure",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_751_SCM_3_IWIS_MA4_P",
                                      "id": "Poco_RJS_751_SCM_3_IWIS_MA4_P",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "text": "Temperature",
                              "id": "temperature",
                              "child": [
                                {
                                  "text": "Annular temperature",
                                  "id": "annumar_temperature",
                                  "child": [
                                    {
                                      "text": "Poco_RJS_751_SCM_3_IWIS_MA4_T",
                                      "id": "Poco_RJS_751_SCM_3_IWIS_MA4_T",
                                      "child": []
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "text": "Wellhead",
                  "id": "wellhead",
                  "child": [
                    {
                      "text": "Pressure",
                      "id": "pressure",
                      "child": [
                        {
                          "text": "Poco_RJS_751_SCM_3_MA_35",
                          "id": "Poco_RJS_751_SCM_3_MA_35",
                          "child": []
                        }
                      ]
                    },
                    {
                      "text": "Temperature",
                      "id": "temperature",
                      "child": [
                        {
                          "text": "Poco_RJS_751_SCM_3_MA_36",
                          "id": "Poco_RJS_751_SCM_3_MA_36",
                          "child": []
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "text": "Observers well",
          "id": "observers_well",
          "child": [
            {
              "text": "RJS_743",
              "id": "rjs_743",
              "child": [{
                "text": "Valves", "id": "valves", "child": [
                  {
                    "text": "ICV", "id": "icv", "child": [
                      {
                        "text": "Top ICV", "id": "top_icv", "child": [
                          {
                            "text": "Pressure", "id": "pressure", "child": [
                              { "text": "Top ICV Annular Pressure", "id": "RJS743_CATS_UZ_UG_P", "child": [] }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }]
            },
            {
              "text": "RJS_731",
              "id": "rjs_731",
              "child": [{
                "text": "Valves", "id": "valves", "child": [
                  {
                    "text": "ICV", "id": "icv", "child": [
                      {
                        "text": "Top ICV", "id": "top_icv", "child": [
                          {
                            "text": "Pressure", "id": "pressure", "child": [
                              { "text": "Top ICV Annular Pressure", "id": "RJS731_INF_SASMIC_P", "child": [] }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }]
            },
            {
              "text": "RJS_744",
              "id": "rjs_744",
              "child": [{
                "text": "Valves", "id": "valves", "child": [
                  {
                    "text": "ICV", "id": "icv", "child": [
                      {
                        "text": "Top ICV", "id": "top_icv", "child": [
                          {
                            "text": "Pressure", "id": "pressure", "child": [
                              { "text": "Top ICV Annular Pressure", "id": "RJS744_CATS_LG_P", "child": [] }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }]
            },
            {
              "text": "RJS_745",
              "id": "rjs_745",
              "child": [{
                "text": "Valves", "id": "valves", "child": [
                  {
                    "text": "ICV", "id": "icv", "child": [
                      {
                        "text": "Top ICV", "id": "top_icv", "child": [
                          {
                            "text": "Pressure", "id": "pressure", "child": [
                              { "text": "Top ICV Annular Pressure", "id": "RJS745_CATS_1G_P", "child": [] }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }]
            },
            {
              "text": "RJS_747",
              "id": "rjs_747",
              "child": [{
                "text": "Valves", "id": "valves", "child": [
                  {
                    "text": "ICV", "id": "icv", "child": [
                      {
                        "text": "Top ICV", "id": "top_icv", "child": [
                          {
                            "text": "Pressure", "id": "pressure", "child": [
                              { "text": "Top ICV Annular Pressure", "id": "RJS747_SUP_CATS_UG_P", "child": [] }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }]
            },
            {
              "text": "RJS_748",
              "id": "rjs_748",
              "child": [{
                "text": "Valves", "id": "valves", "child": [
                  {
                    "text": "ICV", "id": "icv", "child": [
                      {
                        "text": "Top ICV", "id": "top_icv", "child": [
                          {
                            "text": "Pressure", "id": "pressure", "child": [
                              { "text": "Top ICV Annular Pressure", "id": "RJS748_CATS_UG_P", "child": [] }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }]
            },
            {
              "text": "RJS_751_OBS",
              "id": "rjs_743_obs",
              "child": [{
                "text": "Valves", "id": "valves", "child": [
                  {
                    "text": "ICV", "id": "icv", "child": [
                      {
                        "text": "Top ICV", "id": "top_icv", "child": [
                          {
                            "text": "Pressure", "id": "pressure", "child": [
                              { "text": "Top ICV Annular Pressure", "id": "RJS751_SUP_SASMIC_P", "child": [] }
                            ]
                          }
                        ]
                      },
                      {
                        "text": "Middle ICV", "id": "middle_icv", "child": [
                          {
                            "text": "Pressure", "id": "pressure", "child": [
                              { "text": "Middle ICV Annular Pressure", "id": "RJS751_MED_SASMIC_P", "child": [] }
                            ]
                          }
                        ]
                      },
                      {
                        "text": "Bottom ICV", "id": "bottom_icv", "child": [
                          {
                            "text": "Pressure", "id": "pressure", "child": [
                              { "text": "Bottom ICV Annular Pressure", "id": "RJS751_INF_SASMIC_P", "child": [] }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }]
            }
          ]
        }
      ]
  }
]