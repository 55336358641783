import React, { useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../providers/auth";
import styled from "styled-components";
import { Form, Button, Alert } from "react-bootstrap";
import Logo from "../logo.svg";
import { Trans } from "react-i18next";
// import BGImage from "../assets/oil-platform-on-the-ocean-offshore-drilling-for-ga-2YCJZXE-scaled.jpeg";
import BGImage from "../assets/maria-lupan.jpg";

function Login() {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  const usernameRef = useRef();
  const passwordRef = useRef();

  const [error, setError] = useState();

  let { from } = location.state || { from: { pathname: "/" } };
  let login = (formData) => {
    auth.signin(formData, (user) => {
      if (!user) {
        setError(true);
      } else {
        history.replace(from);
      }
    });
  };

  return (
    <Container>
      <FormContainer>
        <Brand>
          <img
            alt=""
            src={Logo}
            height="30"
            className="d-inline-block align-top mr-2"
          />{" "}
          PeTWIN
        </Brand>

        <Title>
          <Trans i18nKey="login.title" />
        </Title>
        <Form
          onSubmit={(event) => {
            event.preventDefault();

            login({
              username: usernameRef.current.value,
              password: passwordRef.current.value,
            });
          }}
        >
          <Form.Group controlId="loginUsername">
            <Form.Label>
              <Trans i18nKey="login.labels.username" />
            </Form.Label>
            <Form.Control
              ref={usernameRef}
              size="lg"
              type="text"
              placeholder="Enter username"
              required
            />
          </Form.Group>

          <Form.Group controlId="loginPassword">
            <Form.Label>
              <Trans i18nKey="login.labels.password" />
            </Form.Label>
            <Form.Control
              ref={passwordRef}
              size="lg"
              type="password"
              placeholder="Password"
              required
            />
          </Form.Group>

          <Button block size="lg" variant="primary" type="submit">
            <Trans i18nKey="login.labels.login" />
          </Button>
        </Form>
        {error && (
          <Alert className="mt-2" variant="danger">
            <Trans i18nKey="login.labels.alert" />
          </Alert>
        )}
      </FormContainer>
      <ImageBackground />
    </Container>
  );
}

export default Login;

const Brand = styled.div`
  font-size: 18px;
  margin-bottom: 1em;
  text-align: left;

  img {
    vertical-align: middle;
  }
`;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row-reverse;
`;

const ImageBackground = styled.div`
  width: calc(100% - 560px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  background-image: ${`url(${BGImage})`};
  background-position: right;

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
  }
`;

const FormContainer = styled.div`
  width: 560px;
  min-height: 100vh;
  display: block;
  background-color: #f7f7f7;
  padding: 173px 55px 55px;
`;

const Title = styled.h1`
  font-size: 30px;
  margin-bottom: 1em;
  line-height: 1.2;
  text-align: center;
`;
