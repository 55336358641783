import * as d3 from "d3";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { keyBy, mapValues } from "lodash";
import React, { useMemo, useRef, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

const numberFormatter = d3.format(",.1~f");

const colors = [
  "#9467bd",
  "#17becf",
  "#d62728",
  "#e377c2",
  "#2ca02c",
  "#1f77b4",
  "#ff7f0e",
  "#bcbd22",
  "#8c564b",
  "#7f7f7f",
];

const getChartTitle = (chart) => chart.userOptions.title.text;

export default function DepthMetrics({ maxDepth, minDepth, series, isLog }) {
  const chartRefs = useRef(mapValues(series, () => React.createRef()));

  const chartDataById = useMemo(() => {
    const chartData = Object.entries(series).map(([id, data], index) => {
      return {
        chart: {
          type: "spline",
          inverted: true,
          height: "600px",
          zoomType: "x",
        },
        colors: [colors[index]],
        credits: {
          enabled: false,
        },
        title: {
          text: id,
        },
        xAxis: {
          crosshair: true,
          reversed: false,
          title: {
            enabled: true,
            text: "Depth",
          },
          labels: {
            format: "{value} m",
          },
          accessibility: {
            rangeDescription: `Range: ${minDepth} to ${maxDepth} m.`,
          },
          maxPadding: 0.05,
          showLastLabel: true,
          max: maxDepth,
          min: minDepth,
          events: {
            setExtremes(event) {
              const { min, max } = event;

              if (event.trigger !== "syncExtremes") {
                Object.values(chartRefs.current).forEach((chartRef) => {
                  if (
                    getChartTitle(this.chart) !==
                    getChartTitle(chartRef.current.chart)
                  ) {
                    const axis = chartRef.current.chart.xAxis[0];

                    if (axis.setExtremes) {
                      axis.setExtremes(min, max, undefined, false, {
                        trigger: "syncExtremes",
                      });
                    }
                  }
                });
              }
            },
          },
        },
        yAxis: {
          type: isLog || id === "PERM" ? "logarithmic" : "linear",
          title: {
            text: id,
          },
          labels: {
            formattter: numberFormatter,
          },
          accessibility: {
            // rangeDescription: "Range: -90°C to 20°C.",
          },
          lineWidth: 2,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          headerFormat: "<b>{series.name}</b><br/>",
          pointFormat: "{point.x} km: {point.y}",
        },
        plotOptions: {
          spline: {
            marker: {
              enable: false,
            },
          },
        },
        series: [
          {
            name: id,
            data: data.map((point) => [point.depth, point.value]),
          },
        ],
      };
    });

    return keyBy(chartData, "title.text");
  }, [isLog, maxDepth, minDepth, series]);

  const handleMouseMove = (e) => {
    Object.values(chartRefs.current).forEach((chartRef) => {
      const { chart } = chartRef.current;
      const event = chart.pointer.normalize(e);
      const point = chart.series[0].searchPoint(event, true);

      if (point) {
        point.onMouseOver(); // Show the hover marker
        point.series.chart.tooltip.refresh(point); // Show the tooltip
        point.series.chart.xAxis[0].drawCrosshair(event, point); // Show the crosshair
      }
    });
  };

  useEffect(() => {
    const reset = () => {};
    const originalReset = Highcharts.Pointer.prototype.reset;

    Highcharts.Pointer.prototype.reset = reset;

    return () => {
      Highcharts.Pointer.prototype.reset = originalReset;
    };
  }, []);

  return (
    <Row>
      {Object.entries(chartDataById).map(([id, chartData]) => {
        return (
          <Col key={id}>
            <HighchartsReact
              ref={chartRefs.current[id]}
              highcharts={Highcharts}
              options={chartData}
              containerProps={{ onMouseMove: handleMouseMove }}
            />
          </Col>
        );
      })}
    </Row>
  );
}
