import { useQuery } from "react-query";
import { useMemo } from "react";
import Loader from "./Loader";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { startCase } from "lodash";
import { getComponentsByClass } from '../api';
import * as React from 'react';

function ComponentsEntities({ component, setComponents, componentBase64 }) {

    const { data, isLoading } = useQuery(["componentsByInstance", componentBase64], () => getComponentsByClass(componentBase64))

    const components = useMemo(() => {

        if (!isLoading) {
            return data.instances.flatMap((property) => {
                return { component: property.instance_name, base64: property.instance_base64_iri, checked: false }
            })
        }
    }, [isLoading, data])

    const handleCheck = (event) => {

        let data = JSON.parse(event.target.name)

        components.forEach((c) => {
            if (c.component === data.component)
                c.checked = !c.checked
        })
        
        setComponents([components])
    }

    return (
        <div>
            {isLoading ? <Loader /> :
                <FormGroup>
                    {
                        components.length > 0 ?
                            components.flatMap((c) => {
                                return (
                                    <FormControlLabel key={c.component} control={<Checkbox onChange={handleCheck}
                                        name={JSON.stringify(c)} />} label={startCase(c.component)} />
                                )
                            })
                            : "This tag hasn't retrieve components information"
                    }
                </FormGroup>
            }
        </div>
    )
}

export default ComponentsEntities;