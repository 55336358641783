import Box from '@mui/material/Box';
import * as React from 'react';
import { Fragment, useState, useContext, useMemo } from 'react';
import { Card } from "react-bootstrap";
import { startCase } from "lodash";
import { useQuery } from "react-query";
import Loader from "../components/Loader";
import TreeView from '@mui/lab/TreeView';
import { styled } from '@mui/material/styles';
import TreeItem from '@mui/lab/TreeItem';
import { alpha } from '@mui/material/styles';
import { treeItemClasses } from '@mui/lab/TreeItem';
import { useTranslation } from 'react-i18next';
import ExpandCircleDownTwoToneIcon from '@mui/icons-material/ExpandCircleDownTwoTone';
import { Button } from "react-bootstrap";
import { getClassTree, getJWT, API_URL } from '../api';
import MaterialEntities from './MaterialEntities'
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import ImageWells from "../assets/map-wells.png";
import Fab from '@mui/material/Fab';
import SatelliteOutlinedIcon from '@mui/icons-material/SatelliteOutlined';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import PropertiesEntities from './PropertiesEntities';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ComponentsEntities from './ComponentsEntities';
import { OntologyExplorerContext } from "../modules/OntologyExplorer";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';

function ClassTree() {

    const { updateDashboard } = useContext(OntologyExplorerContext)
    const { data, isLoading } = useQuery("classTree", () => getClassTree());

    /* Visualization variables */
    const [expanded, setExpanded] = useState([]);
    const [expandedAll, setExpandedAll] = useState("outline-info")
    const [selectedAll, setSelectedAll] = useState("outline-info")
    const [placement, setPlacement] = useState("top-start");
    const [anchorEl, setAnchorEl] = useState("");
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [dataTree, setDataTree] = useState([])

    // if (!isLoading) {
    //     setDataTree([data])
    // }

    useMemo(() => {
        if (!isLoading) {
            setDataTree(data)
        }
    }, [isLoading, data])

    const classTreeWithCounter = async (query) => {
        const remoteData = await fetch(
            `${API_URL}/v1/ontology/class_tree?query=${query}`,
            { method: "get", headers: { Authorization: `Bearer ${getJWT()}` } })
            .then((res) => res.json());

        return remoteData
    }

    const handleKey = async (e) => {
        if (e.key === "Enter") {
            const response = await Promise.resolve(
                classTreeWithCounter(query)
            );
            setDataTree(response)
        }
    }

    const [t] = useTranslation();

    /* Functional variables */
    const [entity, setEntity] = useState("")
    const [entityBase64, setEntityBase64] = useState("")
    const [components, setComponents] = useState([])
    const [childComponents, setChildComponents] = useState([])
    const [tags, setTags] = useState({})


    const handleClick = (event) => {
        setPlacement('top-start');
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds)
    };

    function MinusSquare(props) {
        return (
            <ExpandCircleDownOutlinedIcon fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
                <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
            </ExpandCircleDownOutlinedIcon>
        );
    };

    function PlusSquare(props) {
        return (
            <ExpandCircleDownTwoToneIcon fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
                <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
            </ExpandCircleDownTwoToneIcon>
        );
    };

    function CloseSquare(props) {
        return (
            <ArrowForwardIosTwoToneIcon
                className="close"
                fontSize="inherit"
                style={{ width: 18, height: 18 }}
                {...props} >
                <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
            </ArrowForwardIosTwoToneIcon>
        );
    };

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 'none',
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    const handleSelectClick = () => {
        setSelectedAll((exp) => { return exp === "info" ? "outline-info" : "info" })
        setEntity("")
        setComponents([])
        setChildComponents([])
        selectedAll === "info" ? updateDashboard([]) : updateDashboard("all")
    };

    const handleExpandClick = () => {
        setExpandedAll((sel) => { return sel === "info" ? "outline-info" : "info" })
        let nodeIds = []
        getNodeId(dataTree, nodeIds)
        setExpanded((oldExpanded) =>
            oldExpanded.length === 0 ? nodeIds : [],
        );
    };

    const getNodeId = (node, nodeIds) => {
        nodeIds.push(node.class_data.class_base64_iri)
        node.classes.forEach(t => {
            getNodeId(t, nodeIds)
        })
    }

    const getItem = (item) => {
        if (item.classes.length === 0)
            return (
                <HtmlTooltip enterDelay={200} leaveDelay={200} key={item.class_data.class_base64_iri}
                    title={
                        <Fragment>
                            <MaterialEntities key={item.class_data.class_name} entity={item.class_data.class_name} style={{ backgroundColor: 'red' }}
                                className={item.class_data.class_base64_iri} setEntity={setEntity} setEntityBase64={setEntityBase64}
                                setChildComponents={setChildComponents} setTags={setTags} setComponents={setComponents} />
                        </Fragment>
                    }>
                    <TreeItem nodeId={item.class_data.class_base64_iri} label={startCase(item.class_data.class_name)} />
                </HtmlTooltip>
            )
        return (
            <StyledTreeItem styled={{ backgroundColor: 'red' }} nodeId={item.class_data.class_base64_iri}
                label={`${startCase(item.class_data.class_name)} ${item.query_data.query_counter > 0 ? "(" + item.query_data.query_counter + ")" : ''}`}
                key={`tree-item-${item.class_data.class_name}`}>
                {item.classes.flatMap(tag => getItem(tag))}
            </StyledTreeItem>
        )
    }

    const StyledTreeItem = styled((props) => (
        <TreeItem {...props} />
    ))(({ theme }) => ({
        [`& .${treeItemClasses.iconContainer}`]: {
            '& .close': {
                opacity: 0.2,
            },
        },
        [`& .${treeItemClasses.group}`]: {
            marginLeft: 15,
            paddingLeft: 18,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        },
    }));

    return (
        <div style={{ height: '93vh' }}>
            {isLoading || dataTree?.length < 1 ? (<Loader />) :
                (
                    <Box>
                        <TextField
                            style={{ marginTop: "20px", marginBottom: "20px", size: '150' }}
                            fullWidth
                            id="standard-search"
                            label="Input a word and press 'Enter'"
                            type="search"
                            onKeyDown={handleKey}
                            onChange={event => setQuery(event.target.value)}
                            variant="standard"
                        />
                        <Box key={"container-box"} sx={{ maxHeight: '90vh', flexGrow: 1, maxWidth: '100%', overflowY: 'auto' }}>
                            <Box sx={{ mb: 1, mt: 1, overflowY: 'auto' }} textAlign="center">
                                <Button onClick={handleExpandClick} variant={expandedAll}>
                                    {expandedAll === 'info' ? t('ontologyExplorer.collapseAll') : t('ontologyExplorer.expandAll')}
                                </Button>
                                {" "}
                                <Button onClick={handleSelectClick} variant={selectedAll}>
                                    {selectedAll === 'info' ? t('ontologyExplorer.unselectAll') : t('ontologyExplorer.selectAll')}
                                </Button>
                            </Box>
                            <Box sx={{ maxHeight: '45vh', mb: 1, mt: 1, overflowY: 'auto' }} textAlign="center">
                                <Card style={{ backgroundColor: 'rgba(85, 144, 144, .1)' }}>
                                    <Card.Body>
                                        <TreeView
                                            aria-label="customized"
                                            defaultCollapseIcon={<MinusSquare />}
                                            defaultExpandIcon={<PlusSquare />}
                                            defaultEndIcon={<CloseSquare />}
                                            expanded={expanded}
                                            onNodeToggle={handleToggle}
                                            multiSelect>{getItem(dataTree)}
                                        </TreeView>
                                    </Card.Body>
                                </Card>
                            </Box>
                            {entity !== "" ?
                                (
                                    <div key={entity}>
                                        <Box sx={{ maxHeight: '45vh', mb: 1, mt: 1, overflowY: 'auto' }} textAlign="center">
                                            <Card style={{ backgroundColor: 'rgba(232, 249, 253, 0.8)' }}>
                                                <Card.Body>
                                                    <Card.Title>Components of <strong>{entity}</strong></Card.Title>
                                                    <ComponentsEntities component={entity} componentBase64={entityBase64} setComponents={setComponents} />
                                                </Card.Body>
                                            </Card>
                                        </Box>
                                        <Box sx={{ maxHeight: '45vh', mb: 1, mt: 1, overflowY: 'auto' }} textAlign="center">
                                            {
                                                components.length > 0 ?
                                                    components[0].filter((component) => component.checked).map((c) => {
                                                        return (
                                                            <Card style={{ margin: 0, marginLeft: '40px', backgroundColor: 'rgba(3, 130, 7, 0.15)' }} key={`properties-card-${c.component}`}>
                                                                <Card.Body>
                                                                    <Accordion style={{ backgroundColor: 'rgba(3, 130, 7, 0.15)' }} key={`accordion-${c}`} >
                                                                        <AccordionSummary
                                                                            style={{ margin: 0 }}
                                                                            key={`summary-${c}`}
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header">
                                                                            <Card.Title>Properties of <strong>{c.component}</strong></Card.Title>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails key={`detail-${c}`}>
                                                                            <PropertiesEntities component={c} tags={tags} setTags={setTags} />
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </Card.Body>
                                                            </Card>
                                                        )
                                                    }) : []
                                            }
                                        </Box>
                                        <Box sx={{ maxHeight: '45vh', mt: 1, overflowY: 'auto' }} textAlign="center">
                                            {
                                                components.length > 0 ?
                                                    components[0].filter((component) => component.checked).map((c) => {
                                                        return (
                                                            <Card style={{ marginLeft: '40px', backgroundColor: 'rgba(238, 7, 7, 0.15)' }} key={`components-card-${c.component}`}>
                                                                <Card.Body>
                                                                    <Accordion style={{ backgroundColor: 'rgba(238, 7, 7, 0.15)' }} key={`accordion-${c}`} >
                                                                        <AccordionSummary
                                                                            style={{ margin: 0 }}
                                                                            key={`summary-${c}`}
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header">
                                                                            <Card.Title>Components of <strong>{c.component}</strong></Card.Title>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails key={`detail-${c}`}>
                                                                            <ComponentsEntities component={c.component} setComponents={setChildComponents} componentBase64={c.base64} />
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </Card.Body>
                                                            </Card>
                                                        )
                                                    }) : []
                                            }
                                        </Box>
                                        <Box sx={{ maxHeight: '45vh', mb: 1, mt: 1, overflowY: 'auto' }} textAlign="center">
                                            {
                                                components.length > 0 && childComponents.length > 0 ?
                                                    childComponents[0].filter((component) => component.checked).map((c) => {
                                                        return (
                                                            <Card style={{ marginLeft: '80px', backgroundColor: 'rgba(3, 130, 7, 0.15)' }} key={`properties-card-${c.component}`}>
                                                                <Card.Body>
                                                                    <Accordion style={{ backgroundColor: 'rgba(3, 130, 7, 0.15)' }} key={`accordion-${c}`} >
                                                                        <AccordionSummary
                                                                            key={`summary-${c}`}
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header">
                                                                            <Card.Title>Properties of <strong>{c.component}</strong></Card.Title>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails key={`detail-${c}`}>
                                                                            <PropertiesEntities component={c} tags={tags} setTags={setTags} />
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </Card.Body>
                                                            </Card>

                                                        )
                                                    }) : []
                                            }
                                        </Box>
                                    </div>
                                ) : []
                            }
                        </Box>
                    </Box>
                )
            }

            <Popper style={{ paddingLeft: "2%" }} open={open} anchorEl={anchorEl} placement={placement} transition >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={500}>
                        <Paper>
                            <img alt={"Not found"} src={ImageWells} />
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <Fab variant="extended" size='large' onClick={handleClick} color="primary" aria-label="add" style={{marginLeft: "105%", position: 'absolute', bottom: 0, width: '60%' }} >
                <SatelliteOutlinedIcon sx={{ margin: 5, fontSize: 40 }} />
                <span style={{ paddingRight: 5 }}>Map</span>
            </Fab>
        </div >
    )
}

export default ClassTree;
