import { useState, createContext, useMemo } from "react";
import { useQuery } from "react-query";
import { listMeasurements, getJWT, API_URL } from "../api";
import ClassTree from "../components/ClassTree";
import { TAGS_BY_WELL } from "../constants";
import OntologyDashboard from "../components/OntologyDashboard";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

export const OntologyExplorerContext = createContext();

class InformationStore {
  cache = {};

  async get(c) {

    const cacheKey = `${c.property}/${c.component}`;

    if (cacheKey in this.cache) {
      const cachedData = this.cache[cacheKey];
      return cachedData;
    }

    const remoteData = await fetch(
      `${API_URL}/v1/ontology/quality_instances_of_class_instance/${cacheKey}`,
      { method: "get", headers: { Authorization: `Bearer ${getJWT()}` } }
    ).then((res) => res.json());

    this.cache[cacheKey] = remoteData;

    return remoteData;
  }

}

const informationStore = new InformationStore();

function OntologyExplorer({ startDate, endDate }) {
  const { data = [], isLoading } = useQuery("measurements", () =>
    listMeasurements()
  );

  const [dashboard, updateDashboard] = useState("all");

  const [openToast, setOpenToast] = useState(false);

  const toastHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };

  const removeCache = (cacheKey) => {
    delete informationStore.cache[cacheKey]
  }

  const { data: cdata = TAGS_BY_WELL, error, isLoading: isLoadingCData, } = useQuery(["dashboard-data", dashboard], async () => {

    if (dashboard === "all") {
      return TAGS_BY_WELL;
    }

    let newData = [];

    Object.entries(dashboard).forEach(([key, properties]) => {
      properties
        .filter((e) => e.checked)
        .forEach((e) => {
          newData.push({ component: e.component, property: e.base64 });
        });
    });

    const response = await Promise.all(
      newData.map((c) => informationStore.get(c))
    );

    let filtered = {};

    Object.entries(TAGS_BY_WELL).forEach(([well, tags]) => {
      let measurements = [];
      response.forEach((data) => {

        tags.forEach((entr) => {
          if (
            data.instances[0].instance_name === entr.id ||
            data.instances[0].instance_name.includes(entr.id)
          ) {
            measurements.push(entr);
          }
        });
      });
      filtered[well] = measurements;
    });

    return filtered;
  });

  if (error) {
    throw error;
  }


  const ontologyExplorerContextValue = useMemo(
    () => ({ startDate, endDate, data, cdata, dashboard, updateDashboard, informationStore, removeCache }),
    [cdata, data, endDate, startDate, dashboard, updateDashboard]
  );

  return (
    <div>
      <div class="row" style={{ width: "100%" }}>
        <Stack sx={{ width: "100%" }} spacing={5}>
          <Snackbar
            open={openToast}
            autoHideDuration={5000}
            onClose={toastHandleClose}>
            <Alert
              onClose={toastHandleClose}
              severity="warning"
              sx={{ width: "100%" }}
              style={{ position: "float", bottom: 500, width: "100%" }}>
              This filter hasn't retrieve information. Showing all data!.
            </Alert>
          </Snackbar>
        </Stack>
      </div>
      <div class="row" style={{ margin: 0 }}>
        <div class="col-md-3">
          <OntologyExplorerContext.Provider value={ontologyExplorerContextValue}>
            <ClassTree key={"classTree"} />
          </OntologyExplorerContext.Provider>
        </div>
        <div class="col-md-9">
          {isLoading || isLoadingCData ? (
            []
          ) : (
            <OntologyExplorerContext.Provider value={ontologyExplorerContextValue}>
              <OntologyDashboard key={"ontologyDashboard"} />
            </OntologyExplorerContext.Provider>
          )}
        </div>
      </div>
    </div>
  );
}

export default OntologyExplorer;
