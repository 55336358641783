import * as d3 from "d3";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Button, Modal, Form } from "react-bootstrap";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import { getCorrelations } from "../api";
import styled from "styled-components";
import { getTimeWindow } from "../utils/functions";
import Loader from "./Loader";
import { get, groupBy, keyBy, orderBy } from "lodash";
import { TAG_BY_ID } from "../constants";
import { readableColor } from "polished";
import CorrelationModal from "./CorrelationModal";
import { Trans, useTranslation } from "react-i18next";

const numberFormatter = d3.format(",.2~f");
const displayNumberFormatter = d3.format(",.2~f");

const tagNameFormatter = (id) => {
  const tag = TAG_BY_ID[id];
  if (tag) {
    return `${TAG_BY_ID[id]?.description} (${TAG_BY_ID[id]?.well})`;
  }

  return id;
};

function CorrelationMatrix({
  measurements,
  method = "pearson",
  startDate,
  endDate,
}) {
  const { data = [], isLoading } = useQuery(
    startDate &&
    endDate &&
    measurements.length > 2 && [
      "correlations",
      { measurements, startDate, endDate, method },
    ],
    async ({ queryKey }) => {
      const [, params] = queryKey;

      return getCorrelations({
        ...params,
        start_date: params.startDate.toISOString(),
        end_date: params.endDate.toISOString(),
        time_window: getTimeWindow(params.startDate, params.endDate),
        fill: "none",
      });
    }
  );
  const [t] = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showDashboardModal, setShowDashboardModal] = useState(false);
  const [cdata, setCdata] = useState([]);
  const [modalProps, setModalProps] = useState([
    { label: t("modal.dashboards.timeline"), id: 0, checked: true },
    { label: t("modal.dashboards.scatterplot"), id: 1, checked: false },
  ]);
  const [dashboard, setDashboard] = useState(0);

  const [heatmapData, heatmapKeys] = useMemo(() => {
    if (isLoading) {
      return [[], []];
    }

    const grouped = groupBy(data, "measurement_1");

    const asArray = Object.entries(grouped).map(([measurement, corrData]) => {
      const keyed = keyBy(corrData, "measurement_2");

      return {
        measurement,
        ...Object.fromEntries(
          measurements.map((id) => [id, get(keyed, [id, "correlation"], 0)])
        ),
      };
    });

    const sortedArray = orderBy(
      asArray,
      (item) => {
        const { measurement, ...values } = item;
        return Object.values(values).reduce(
          (sum, corr) => sum + Math.abs(corr),
          0
        );
      },
      "desc"
    );

    return [
      sortedArray,
      orderBy(
        measurements,
        (id) => {
          const items = grouped[id] || [];
          return items.reduce(
            (sum, corrData) => sum + Math.abs(corrData.correlation),
            0
          );
        },
        "desc"
      ),
    ];
  }, [data, isLoading, measurements]);

  if (isLoading) {
    return (
      <div style={{ marginTop: "150px", height: 300 }}>
        <Loader width="100" />
      </div>
    );
  }

  const handleClose = () => {
    setShowModal(false);
    setShowDashboardModal(false);
  };

  const closeModals = () => {
    setShowModal(false);
    setShowDashboardModal(false);
  };

  const backModal = () => {
    setShowModal(true);
    setShowDashboardModal(false);
  };

  const handleShow = (cell, event) => {
    setCdata([cell.xKey, cell.yKey]);
    setShowModal(true);
  };

  const handleChange = (e) => {
    let selectedId = parseInt(e.target.id.split("-")[1]);
    setModalProps([
      {
        label: t("modal.dashboards.timeline"),
        id: 0,
        checked: selectedId === 0,
      },
      {
        label: t("modal.dashboards.scatterplot"),
        id: 1,
        checked: selectedId === 1,
      },
    ]);
  };

  const applyModal = () => {
    if (modalProps[0].checked) {
      setDashboard(0);
    } else {
      setDashboard(1);
    }
    setShowModal(false);
    setShowDashboardModal(true);
  };

  return (
    <div
      style={{ height: "calc(100vh - 80px)", width: "100%", margin: "0 auto" }}
    >
      <ResponsiveHeatMap
        data={heatmapData}
        keys={heatmapKeys}
        indexBy="measurement"
        margin={{ top: 10, right: 10, bottom: 10, left: 200 }}
        forceSquare={true}
        cellShape={CustomCell}
        tooltipFormat={displayNumberFormatter}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        onClick={handleShow}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: -40,
          format: tagNameFormatter,
        }}
        colors="RdYlBu"
        hoverTarget="cell"
        cellHoverOthersOpacity={0.25}
        tooltip={CustomTooltip}
      />
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        dialogAs={ModalDialog}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans i18nKey="modal.title.selectType" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-2 md-2">
          <Form>
            <Form.Check type="radio" id="check">
              {modalProps.map((prop) => {
                return (
                  <div key={prop.id} className="mb-2">
                    <Form.Check.Input
                      id={`radio-${prop.id}`}
                      type="radio"
                      isValid
                      checked={prop.checked}
                      onChange={handleChange}
                    />
                    <Form.Check.Label
                      style={{ color: "gray", fontSize: "18px" }}
                    >
                      {prop.label}
                    </Form.Check.Label>
                  </div>
                );
              })}
            </Form.Check>
          </Form>
        </Modal.Body>
        <Modal.Footer className="mt-2">
          <Button variant="primary" onClick={applyModal}>
            <Trans i18nKey="common.components.applyButton" />
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            <Trans i18nKey="common.components.backButton" />
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDashboardModal}
        onHide={handleClose}
        size="lg"
        dialogAs={ModalDialog}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {tagNameFormatter(cdata[0])} - {tagNameFormatter(cdata[1])}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-2 md-2 mt-0" style={{ height: "50vh" }}>
          <CorrelationModal
            dashboard={dashboard}
            measurements={cdata}
            endDate={endDate}
            startDate={startDate}
          />
        </Modal.Body>
        <Modal.Footer className="mt-2">
          <Button variant="secondary" onClick={backModal}>
            <Trans i18nKey="common.components.backButton" />
          </Button>
          <Button variant="danger" onClick={closeModals}>
            <Trans i18nKey="common.components.closeButton" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CorrelationMatrix;

const CustomTooltip = ({ xKey, yKey, value, id }) => {
  return (
    <div key={id}>
      {tagNameFormatter(xKey)} - {tagNameFormatter(yKey)}:{" "}
      <strong>{displayNumberFormatter(value)}</strong>
    </div>
  );
};

const CustomCell = React.memo(
  ({
    x,
    y,
    width,
    height,
    color,
    opacity,
    borderWidth,
    borderColor,
    onHover,
    onLeave,
    onClick,
    data,
  }) => (
    <g
      transform={`translate(${x}, ${y})`}
      style={{ cursor: "pointer" }}
      onMouseEnter={onHover}
      onMouseMove={onHover}
      onMouseLeave={onLeave}
      onClick={onClick ? (event) => onClick(data, event) : undefined}
    >
      <rect
        x={width * -0.5}
        y={height * -0.5}
        width={width}
        height={height}
        fill={color}
        fillOpacity={opacity}
        strokeWidth={borderWidth}
        stroke={borderColor}
        strokeOpacity={opacity}
      />
      <text
        dominantBaseline="central"
        textAnchor="middle"
        style={{
          fill: readableColor(color),
          fontSize: 9,
        }}
        fillOpacity={opacity}
      >
        {numberFormatter(data.value)}
      </text>
    </g>
  )
);

const ModalDialog = styled(Modal.Dialog)`
  width: 80vw;
  max-width: unset !important;
`;
