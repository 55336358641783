import { merge, keyBy } from "lodash";

export function getTimeWindow(startDate, endDate) {
  const difference = endDate.diff(startDate, "days");

  if (difference <= 1) {
    return "5m";
  }

  if (difference <= 3) {
    return "1h";
  }

  if (difference <= 7) {
    return "3h";
  }

  if (difference <= 30) {
    return "6h";
  }

  if (difference <= 90) {
    return "1d";
  }

  if (difference <= 240) {
    return "3d";
  }

  return "7d";
}

export function mergeData(data) {
  const data0 = data[0].data.map((d) => {
    return {
      mean0: d.mean > 0 ? d.mean : 0,
      time: d.time,
    };
  });

  const data1 = data[1].data.map((d) => {
    return {
      mean1: d.mean > 0 ? d.mean : 0,
      time: d.time,
    };
  });

  merge(keyBy(data0, "time"), keyBy(data1, "time"));

  return data0;
}

export function colorHash(inputString) {
  let sum = 0;

  for (let i in inputString) {
    sum += inputString.charCodeAt(i);
  }

  let r = ~~(
    ("0." +
      Math.sin(sum + 1)
        .toString()
        .substr(6)) *
    256
  );
  let g = ~~(
    ("0." +
      Math.sin(sum + 2)
        .toString()
        .substr(6)) *
    256
  );
  let b = ~~(
    ("0." +
      Math.sin(sum + 3)
        .toString()
        .substr(6)) *
    256
  );

  let rgb = "rgb(" + r + ", " + g + ", " + b + ")";

  let hex = "#";

  hex += ("00" + r.toString(16)).substr(-2, 2).toUpperCase();
  hex += ("00" + g.toString(16)).substr(-2, 2).toUpperCase();
  hex += ("00" + b.toString(16)).substr(-2, 2).toUpperCase();

  return {
    r: r,
    g: g,
    b: b,
    rgb: rgb,
    hex: hex,
  };
}
