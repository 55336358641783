import { ResponsiveLine } from "@nivo/line";
import React, { useMemo } from "react";
import { TAG_TYPE_COLORS } from "../constants";
import { min, max } from "lodash";

function MeasurementLineChart({ tag, data, startDate, endDate }) {
  const chartData = useMemo(() => {
    return [
      {
        id: "mean",
        data: data.map((point) => ({
          x: point.time,
          y: point.mean,
        })),
      },
    ];
  }, [data]);

  return (
    <ResponsiveLine
      data={chartData}
      colors={[TAG_TYPE_COLORS[tag.type]]}
      enableArea={true}
      enablePoints={false}
      enableGridY={false}
      enableGridX={false}
      axisTop={false}
      axisBottom={false}
      axisLeft={false}
      axisRight={false}
      yScale={{
        clamp: true,
        nice: false,    
        type: "linear",
        min: min(chartData[0].data.map((d) => d.y)),
        max: max(chartData[0].data.map((d) => d.y)),
      }}
      xScale={{
        type: "linear",
        min: startDate.valueOf(),
        max: endDate.valueOf(),
      }}
      margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
    />
  );
}

export default MeasurementLineChart