import * as d3 from "d3";
import { last, mean } from "lodash";
import React, { useMemo, useState, useContext } from "react";
import { Badge, Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import styled from "styled-components";
import { getMeasurement } from "../api";
import { getTimeWindow } from "../utils/functions";
import Loader from "./Loader";
import { Multiselect } from 'multiselect-react-dropdown';
import MeasurementLineChart from "./MeasurementLineChart";
import MeasurementLineHighchart from "./MeasurementLineHighchart";
import MeasurementAnomalies from "./MeasurementAnomalies";
import { keyBy } from "lodash";
import { Trans, useTranslation } from 'react-i18next';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import RangeSlider from 'react-bootstrap-range-slider';
import { OntologyExplorerContext } from "../modules/OntologyExplorer";

const numberFormatter = d3.format(",.2~f");

function MeasurementLine({ measurementData, tag }) {

  const { startDate, endDate, cdata, showAll } = useContext(OntologyExplorerContext)
  const [showModal, setShowModal] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [filterData, setFilterData] = useState(cdata)
  const [nEstimators, setNEstimators] = useState(100);
  const [contamination, setContamination] = useState(0.25);
  const [maxFeatures, setMaxFeatures] = useState(0.5);

  const [t] = useTranslation();

  const { data = [], isLoading } = useQuery(
    startDate &&
    endDate && [
      "measurement",
      {
        measurement: tag.id,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        time_window: getTimeWindow(startDate, endDate),
        fill: "none",
      },
    ],
    ({ queryKey }) => {
      const [, params] = queryKey;
      return getMeasurement(params)
    }
  );

  localStorage.setItem(JSON.stringify(tag), JSON.stringify(data))

  const handleClose = () => setShowModal(false);
  const handleShow = () => {

    if (showAll) {
      setFilterData((filterData) => [{
        tag: tag,
        data: data
      }])
    } else {

      setFilterData(() => {
        return Object.entries(cdata).flatMap(([well, tags]) => {
          return tags.flatMap((tag) => [{
            tag: tag,
            data: JSON.parse(localStorage.getItem(JSON.stringify(tag)))
          }]
          )
        })
      })
    }

    setNEstimators(Math.trunc(data.length / 10))
    setShowModal(true)
  };

  const meanValue = useMemo(() => mean(data.map((x) => x.mean)), [data]);

  const isTagAvailable = useMemo(() => {
    const existingData = keyBy(measurementData, "name");

    return {
      ...existingData,
      OIL_PRODUCED: { name: "OIL_PRODUCED" },
    };
  }, [measurementData]);

  const opts = []

  Object.entries(cdata).forEach(([well, tags]) => {
    tags = tags.filter(tagWell => isTagAvailable[tagWell.id])
    tags = tags.filter(tagWell => {
      return !(tagWell.id === tag.id && tagWell.well === tag.well) && tagWell.id !== 'OIL_PRODUCED'
    })
    tags.forEach(tagWell => {
      opts.push({
        id: well + '|' + tagWell.id,
        label: well + ' - ' + tagWell.description,
        well: well,
        type: tagWell.type,
        unit: tagWell.unit
      })
    })
  })

  const anomalyFlag = (checked) => {
    setShowDropDown(checked)
  }

  const changeContamination = (changeEvent) => {
    setContamination(changeEvent.target.value)
  }

  const changeMaxFeatures = (changeEvent) => {
    setMaxFeatures(changeEvent.target.value)
  }

  const changeNEstimators = (changeEvent) => {
    setNEstimators(changeEvent.target.value)
  }

  const resetValues = () => {
    setNEstimators(Math.trunc(data.length / 10))
    setContamination(0.25)
    setMaxFeatures(0.5)
  }

  const onSelect = (selectedList, selectedItem) => {
    const tag = {
      well: selectedItem.well,
      description: selectedItem.label.split(' - ')[1],
      type: selectedItem.type,
      unit: selectedItem.unit,
      id: selectedItem.id.split('|')[1],
    };
    setFilterData((filterData) => [
      ...filterData,
      {
        tag: tag,
        data: JSON.parse(localStorage.getItem(JSON.stringify(tag)))
      }
    ])
  }

  const onRemove = (selectedList, removedItem) => {
    setFilterData(filterData.filter(c =>
      !(c.tag.well === removedItem.id.split('|')[0] && c.tag.id === removedItem.id.split('|')[1])
    ))
  }

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <strong>{tag.description}</strong>
        <div>
          <Badge variant="light">
            <Trans i18nKey="common.labels.badgeAvg" />{" "}
            <strong>
              {isLoading || data.length === 0
                ? "–"
                : numberFormatter(meanValue) + " " + tag.unit}
            </strong>
          </Badge>
          <Badge className="ml-2" variant="light">
            <Trans i18nKey="common.labels.badgeLast" />{" "}
            <strong>
              {isLoading || data.length === 0
                ? "–"
                : numberFormatter(last(data).mean) + " " + tag.unit}
            </strong>
          </Badge>
        </div>
      </div>
      <div style={{ flexShrink: false, width: 200, height: 50 }}>
        {isLoading ? (
          <Loader />
        ) : (
          data.length > 0 && (
            <ChartWrapper onClick={handleShow}>
              <MeasurementLineChart
                onClick
                data={data}
                tag={tag}
                startDate={startDate}
                endDate={endDate} />
            </ChartWrapper>
          )
        )}
      </div>
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        dialogAs={ModalDialog}>
        <Modal.Header style={{ margin: 0 }} closeButton>
          <Modal.Title style={{ width: '100%' }}>
            <Container style={{ 'margin-left': '5%', width: '100%' }}>
              <Row>
                <Col>
                  <span>{tag.well + ' - ' + tag.description}</span>
                </Col>
                {showAll ?
                  <Col>
                    <BootstrapSwitchButton
                      size="m"
                      width="200"
                      offlabel='Comparison'
                      offstyle='secondary'
                      onlabel='Anomaly Detection'
                      onstyle="info"
                      checked={showDropDown}
                      onChange={anomalyFlag} />
                  </Col> : []
                }
              </Row>
            </Container>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-2 md-2" >
          <div className="mb-2" style={{ height: "50vh" }} hidden={showDropDown}>
            <MeasurementLineHighchart
              hidden={showDropDown}
              onClick
              data={filterData} />
          </div>
          <div className="mb-2" style={{ height: "50vh", width: "100%" }} hidden={!showDropDown}>
            <MeasurementAnomalies
              hidden={!showDropDown}
              measurement={tag.id}
              startDate={startDate}
              endDate={endDate}
              contamination={contamination}
              maxFeatures={maxFeatures}
              nEstimators={nEstimators} />
          </div>
          <div hidden={showDropDown || !showAll}>
            <Multiselect
              placeholder={t('modal.dashboards.compareWith')}
              options={opts}
              displayValue="label"
              groupBy="well"
              onSelect={onSelect}
              selectionLimit={4}
              closeIcon='cancel'
              onRemove={onRemove}
              showCheckbox={true}
            />
          </div>
          <div hidden={!showDropDown}>
            <div class="container">
              <div class="row">
                <div class="col-sm">
                  <Trans i18nKey="modal.dashboards.contamination" />
                  <small className="ml-2">
                    <RangeSlider
                      placeholder={t('modal.dashboards.contamination')}
                      value={contamination}
                      step={0.01}
                      min={0.01}
                      max={0.50}
                      onChange={changeContamination}
                    />
                  </small>
                </div>
                <div class="col-sm">
                  <Trans i18nKey="modal.dashboards.NEstimators" />
                  <small className="ml-2">
                    <RangeSlider
                      value={nEstimators}
                      min={Math.trunc(data.length / 10)}
                      max={data.length}
                      onChange={changeNEstimators}
                    />
                  </small>
                </div>
                <div class="col-sm">
                  <Trans i18nKey="modal.dashboards.maxFeatures" />
                  <small className="ml-2">
                    <RangeSlider
                      value={maxFeatures}
                      step={0.01}
                      min={0.00}
                      max={1.00}
                      onChange={changeMaxFeatures}
                    /></small>
                </div>
                <div class="col-ml">
                  <Button variant="outline-secondary" onClick={resetValues}>
                    <Trans i18nKey="common.components.resetButton" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="mt-2">
          <Button variant="secondary" onClick={handleClose}>
            <Trans i18nKey="common.components.backButton" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default MeasurementLine;


const ChartWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const ModalDialog = styled(Modal.Dialog)`
  width: 80vw;
  max-width: unset !important;
`;
