import { mean, get } from "lodash";
import { transparentize } from "polished";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Loader from "../components/Loader";
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  GeoJSON,
  LayersControl,
  LayerGroup,
} from "react-leaflet";
import styled from "styled-components";
import { getLocationWells } from "../api";
import BlocksGEOJson from "../assets/Blocos_Exploratorios_29072021.json";
import FieldsGEOJson from "../assets/Campos_de_Produção_29072021.json";

function MapExplorer({ props }) {
  const [t, i18n] = useTranslation();
  const [map, setMap] = useState(null);
  const { data = [], isLoading } = useQuery("wells", () =>
    getLocationWells()
  );

  const dataMap = useMemo(() => {
    return data.filter(x => x.longitude !== 0 && x.latitude !== 0)
  }, [data]);

  useEffect(() => {
    if (!map) {
      return;
    }

    let lastZoom;
    const tooltipThreshold = 12;
    function onZoomEnd() {
      let zoom = map.getZoom();
      if (
        zoom < tooltipThreshold &&
        (!lastZoom || lastZoom >= tooltipThreshold)
      ) {
        map.eachLayer(function (l) {
          if (l.getTooltip()) {
            let tooltip = l.getTooltip();
            l.unbindTooltip().bindTooltip(tooltip, {
              permanent: false,
            });
          }
        });
      } else if (
        zoom >= tooltipThreshold &&
        (!lastZoom || lastZoom < tooltipThreshold)
      ) {
        map.eachLayer(function (l) {
          if (l.getTooltip()) {
            let tooltip = l.getTooltip();
            l.unbindTooltip().bindTooltip(tooltip, {
              permanent: true,
            });
          }
        });
      }
    }

    map.on("zoomend", onZoomEnd);

    return () => {
      map.off("zoomend", onZoomEnd);
    };
  }, [map]);

  return (

    <div style={{ height: `calc(100vh - 80px)`, width: `100vw` }}>
      {
        isLoading ? (
          <Loader />
        ) : (
          <MapContainer
            whenCreated={setMap}
            center={[
              mean(dataMap.map((x) => x.latitude)),
              mean(dataMap.map((x) => x.longitude)),
            ]}
            zoom={12}
            style={{ height: `100%`, width: `100%` }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {dataMap.map(({ id, name, longitude, latitude }) => (
              <Marker key={id} position={[latitude, longitude]}>
                <Tooltip direction="right" opacity={1} permanent>
                  <span>{name}</span>
                </Tooltip>
              </Marker>
            ))}

            <div className="leaflet-bottom leaflet-left">
              <LegendContainer className="leaflet-control leaflet-bar">
                <LegendContainerTitle>
                  {t("common.labels.legend")}
                </LegendContainerTitle>

                <LegendContainerGrid>
                  <LegendContainerColoredBlock
                    color={blockColorsMapping["Partilha 1"]}
                  />
                  <div>{`${t("mapExplorer.sharing")} 1`}</div>
                  <LegendContainerColoredBlock
                    color={blockColorsMapping["Partilha 2"]}
                  />
                  <div>{`${t("mapExplorer.sharing")} 2`}</div>
                  <LegendContainerColoredBlock
                    color={blockColorsMapping["Partilha 3"]}
                  />
                  <div>{`${t("mapExplorer.sharing")} 3`}</div>
                  <LegendContainerColoredBlock
                    color={blockColorsMapping["Partilha 4"]}
                  />
                  <div>{`${t("mapExplorer.sharing")} 4`}</div>

                  <LegendContainerColoredBlock
                    color={blockColorsMapping["Rodada 2"]}
                  />
                  <div>{`${t("mapExplorer.round")} 2`}</div>
                  <LegendContainerColoredBlock
                    color={blockColorsMapping["Rodada 3"]}
                  />
                  <div>{`${t("mapExplorer.round")} 3`}</div>
                  <LegendContainerColoredBlock
                    color={blockColorsMapping["Rodada 7"]}
                  />
                  <div>{`${t("mapExplorer.round")} 7`}</div>

                  <LegendContainerColoredBlock color={"#18D72E"} />
                  <div>{t("mapExplorer.productionFields")}</div>
                </LegendContainerGrid>
              </LegendContainer>
            </div>

            <LayersControl key={i18n.language} position="topright">
              <LayersControl.Overlay
                checked
                name={t("mapExplorer.productionFields")}
              >
                <LayerGroup>
                  <GeoJSON
                    data={FieldsGEOJson}
                    onEachFeature={geoJsonDescriptor}
                    style={() => ({ color: "#18D72E", strokeWidth: 1 })}
                  />
                </LayerGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay
                checked
                name={t("mapExplorer.explorationBlocks")}
              >
                <LayerGroup>
                  <GeoJSON
                    data={BlocksGEOJson}
                    onEachFeature={geoJsonDescriptor}
                    style={(feature) => ({
                      color: get(
                        blockColorsMapping,
                        [feature.properties.RODADA],
                        "#ffffff"
                      ),
                    })}
                  />
                </LayerGroup>
              </LayersControl.Overlay>
            </LayersControl>
          </MapContainer>)}
    </div>
  );
}

export default MapExplorer;

function geoJsonDescriptor(feature, layer) {
  layer.bindPopup(
    `<dl>
      ${Object.entries(feature.properties)
      .map(([property, description]) => {
        return `
          <dt>${property}</dt>
          <dd>${description || "<em>(Not Set)</em>"}</dd>
        `;
      })
      .join("")}
    </dl>`,
    { maxHeight: 450, minWidth: 250 }
  );
}

const blockColorsMapping = {
  "Partilha 1": "#838383",
  "Partilha 2": "#B5AAFA",
  "Partilha 3": "#CBA0A1",
  "Partilha 4": "#670441",
  "Rodada 2": "#15C55A",
  "Rodada 3": "#FEB633",
  "Rodada 7": "#B8FFBB",
};

const LegendContainer = styled.div`
  background: #fff;
  padding: 12px;
`;

const LegendContainerTitle = styled.h3`
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 600;
`;

const LegendContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr;
  gap: 10px;
  font-size: 12px;
`;

const LegendContainerColoredBlock = styled.div`
  width: 25px;
  height: 20px;
  border: ${(props) => `${props.color} 1px solid`};
  background: ${(props) => transparentize(0.8, props.color)};
`;
