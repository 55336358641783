import { useQuery } from "react-query";
import Loader from "./Loader";
import { startCase } from "lodash";
import Divider from '@mui/material/Divider'
import { getInstancesByClass } from '../api';
import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import OilBarrelTwoToneIcon from '@mui/icons-material/OilBarrelTwoTone';

function MaterialEntities({ className, entity, setEntity, setEntityBase64, setTags, setComponents, setChildComponents }) {

    const { data, isLoading } = useQuery(["instancesByClass", className], () => getInstancesByClass(className))

    const handleClick = (event, instance) => {
        setEntity(instance.instance_name)
        setEntityBase64(instance.instance_base64_iri)
        setTags([])
        setComponents([])
        setChildComponents([])
    };

    return (
        <div>
            {isLoading ? <Loader /> :
                <div style={{ backgroundColor: 'rgba(255, 255, 100, 0.05)' }}>
                    <h5> Instances of <strong>{startCase(entity)}</strong></h5>
                    <Divider />
                    <Divider />
                    {
                        Object.values(data.instances).flatMap((instance) => {
                            return (
                                <div key={instance}>
                                    <ListItemButton onClick={(event) => handleClick(event, instance)} key={instance.instance_name}>
                                        <ListItemIcon>
                                            <OilBarrelTwoToneIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={instance.instance_name} />
                                    </ListItemButton>
                                </div>)
                        })
                    }
                </div>
            }
        </div>
    )
}

export default MaterialEntities;
