import React from "react";
import Logo from "../LoaderLogo.svg"

export default function Loader({width="45"}) {
  return (
    <div className="d-flex justify-content-center align-items-center h-200">
      <img
        alt="Loading"
        src={Logo}
        height={width}
        className="d-inline-block align-top mr-2"
      />
    </div>
  );
}
