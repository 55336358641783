import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        react: {
            useSuspense: false
        },
        debug: false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    login: {
                        title: 'Login to continue',
                        labels: {
                            username: 'Username',
                            password: 'Password',
                            remember: 'Keep me signed in',
                            login: 'Login',
                            alert: 'Sorry! Looks like you have provided invalid credentials.'
                        }
                    },
                    menu: {
                        title: {
                            correlation: 'Correlations',
                            dashboard: 'Dashboard',
                            mapExplorer: 'Map Explorer',
                            nmr: 'NMR',
                            catsAndSasmic: 'CaTS and SASMIC',
                            anomalyDetection: "Anomaly Detection",
                            ontologyExplorer: "Ontology Explorer"
                        },
                    },
                    correlationForm: {
                        title: {
                            options: 'Options'
                        },
                        labels: {
                            selectedTags: 'Selected Tags',
                            searchTags: 'Search for tag...',
                            correlationMethod: 'Correlation Method'
                        }
                    },
                    modal: {
                        title: {
                            selectType: 'Select a dashboard type to showing:'
                        },
                        dashboards: {
                            timeline: 'Timeline Chart',
                            scatterplot: 'ScatterPlot Chart',
                            compareWith: 'Compare with...',
                            contamination: 'Contamination',
                            maxFeatures: 'Max features',
                            NEstimators: 'N° Estimators'
                        }
                    },
                    nmr: {
                        labels: {
                            groupData: 'Showing data grouped by: ',
                            filterData: 'Filter:',
                            selected: 'selected'
                        }
                    },
                    common: {
                        components: {
                            loadingText: 'Loading...',
                            backButton: 'Go back',
                            closeButton: 'Close',
                            resetButton: 'Reset',
                            applyButton: 'Apply',
                            dropdownCompare: 'Compare with...'
                        },
                        labels: {
                            well: 'Well',
                            badgeAvg: 'Avg',
                            badgeLast: 'Last',
                            metric: 'Metric',
                            legend: 'Legend'
                        }
                    },
                    dashboards: {
                        labels: {
                            oilProduced: 'Oil Produced',
                            average: 'Average',
                            date: 'Date',
                            filterTags: 'Filter tags'
                        }
                    },
                    mapExplorer: {
                        productionFields: 'Production Fields',
                        explorationBlocks: 'Exploration Blocks',
                        sharing: 'Sharing',
                        round: 'Round'
                    },
                    ontologyExplorer: {
                        expandAll: 'Expand all',
                        collapseAll: 'Collapse all',
                        selectAll: 'Select all',
                        unselectAll: 'Unselect all',
                    }
                }
            },
            pt: {
                translation: {
                    login: {
                        title: 'Faça login para continuar',
                        labels: {
                            username: 'Usuário',
                            password: 'Senha',
                            remember: 'Mantenha-me conectado',
                            login: 'Entrar',
                            alert: 'As credenciales fornecidas não foram corretas.'
                        }
                    },
                    menu: {
                        title: {
                            correlation: 'Correlações',
                            dashboard: 'Dashboard',
                            mapExplorer: 'Explorador de Mapa',
                            nmr: 'NMR',
                            catsAndSasmic: 'CaTS e SASMIC',
                            anomalyDetection: "Deteção de Anomalias",
                            ontologyExplorer: "Explorador de Ontologias"
                        }
                    },
                    correlationForm: {
                        title: {
                            options: 'Opções'
                        },
                        labels: {
                            selectedTags: 'Tags Selecionadas',
                            searchTags: 'Pesquisa por tag...',
                            correlationMethod: 'Método de Correlação'
                        }
                    },
                    modal: {
                        title: {
                            selectType: 'Selecione um tipo de dashboard para mostrar:'
                        },
                        dashboards: {
                            timeline: 'Gráfico de Linha de Tempo',
                            scatterplot: 'Gráfico ScatterPlot',
                            compareWith: 'Compare com...',
                            contamination: 'Contaminação',
                            maxFeatures: 'Max Características',
                            NEstimators: 'N° Estimadores'                            
                        }
                    },
                    nmr: {
                        labels: {
                            groupData: 'Mostrar dados agrupados por: ',
                            filterData: 'Filtro:',
                            selected: 'selecionados'
                        }
                    },
                    common: {
                        components: {
                            loadingText: 'Carregando...',
                            backButton: 'Voltar',
                            closeButton: 'Fechar',
                            resetButton: 'Limpar',
                            applyButton: 'Aplicar',
                            dropdownCompare: 'Comparar com...'
                        },
                        labels: {
                            well: 'Poço',
                            badgeAvg: 'Média',
                            badgeLast: 'Ultima',
                            metric: 'Métrica',
                            legend: 'Legenda'
                        }
                    },
                    dashboards: {
                        labels: {
                            oilProduced: 'Óleo produzido',
                            average: 'Promédio',
                            date: 'Data',
                            filterTags: 'Filtrar tags'
                        }
                    },
                    mapExplorer: {
                        productionFields: 'Campos de Produção',
                        explorationBlocks: 'Blocos de Exploração',
                        sharing: 'Partilha',
                        round: 'Rodada'
                    },
                    ontologyExplorer: {
                        expandAll: 'Expandir todo',
                        collapseAll: 'Recolher todo',
                        selectAll: 'Selecionar todo',
                        unselectAll: 'Deselecionar todo',
                    }
                }
            }
        }
    });

export default i18n;