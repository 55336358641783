import React, { useMemo } from "react";
import { useQueries } from "react-query";
import { getMeasurement } from "../api";
import styled from "styled-components";
import Loader from "./Loader";
import { getTimeWindow } from "../utils/functions";
import MeasurementScatterPlot from "./MeasurementScatterPlot";
import MeasurementLineHighchart from "./MeasurementLineHighchart";
import { TAG_BY_ID } from "../constants";

function CorrelationModal({ measurements, dashboard, startDate, endDate }) {

    const tagName = (id) => TAG_BY_ID[id]

    const tags = measurements.map(m => tagName(m))

    const results = useQueries(
        tags.map(tag => {
            return {
                queryKey: startDate && endDate && [
                    "measurement",
                    {
                        measurement: tag.id,
                        start_date: startDate.toISOString(),
                        end_date: endDate.toISOString(),
                        time_window: getTimeWindow(startDate, endDate),
                        fill: "none",
                    },
                ],
                queryFn: ({ queryKey }) => {
                    const [, params] = queryKey;
                    return getMeasurement(params)
                }
            }
        }, [])
    )

    const chartData = useMemo(() => {
        let cdata = []
        let index = 0
        results.forEach((result) => {
            if (!result.isLoading && result.isSuccess) {
                cdata.push({ tag: { description: tags[index].description, well: tags[index].well }, data: result.data })
            }
            index = index + 1
        })
        return cdata
    }, [results, tags])

    return (
        <div style={{ flexShrink: false, width: "100%", height: "100%" }}>
            <ChartWrapper>
                {
                    (results[0].isLoading || results[1].isLoading) ?
                        (<Loader />) : (
                            dashboard === 0 ? (
                                <MeasurementLineHighchart
                                    onClick
                                    data={chartData} />
                            ) : (
                                <MeasurementScatterPlot
                                    data={chartData} />
                            )
                        )
                }
            </ChartWrapper>
        </div>
    )
}

export default CorrelationModal;

const ChartWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
`;
