import Highcharts from "highcharts"
import more from "highcharts/highcharts-more";
import styled from "styled-components";
import { useMemo } from "react";
import { maxBy, minBy } from "lodash";
import HighchartsReact from "highcharts-react-official";
import { colorHash } from "../utils/functions";

more(Highcharts);

function MeasurementLineHighchart({ data, isCard = false }) {

    // console.log(data)    

    const chartOptions = useMemo(() => {

        const charData = isCard ? data?.map((d) => {
            const { hex: color } = colorHash(d.title);
            return {
                name: d.title,
                data: d.data?.map(({ mean, time }) => [time, mean]),
                zIndex: 1,
                marker: {
                    fillColor: "white",
                    lineWidth: 2,
                    lineColor: color,
                },
                color: color,
            }
        }) : data?.map((d) => {
            const { hex: color } = colorHash(d.tag.well + d.tag.description);
            return {
                name: d.tag.well + ' - ' + d.tag.description,
                data: d.data?.map(({ mean, time }) => [time, mean]),
                zIndex: 1,
                marker: {
                    fillColor: "white",
                    lineWidth: 2,
                    lineColor: color,
                },
                color: color,
            }
        })

        let maxData = []
        let minData = []

        data?.forEach((d) => {
            maxData.push(maxBy(d.data, 'mean'));
            minData.push(minBy(d.data, 'mean'));
        })

        return {
            chart: {
                type: "areaspline",
                height: "30%",
                zoomType: "x",
            },
            title: {
                text: null,
            },
            xAxis: {
                type: "datetime"
            },
            yAxis: {                
                title: {
                    text: null,
                },
                min: minBy(minData, 'mean').mean,
                max: maxBy(maxData, 'mean').mean,
            },
            tooltip: {
                crosshairs: true,
                shared: true,
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                areaspline: {
                    fillOpacity: 0.2
                },
            },
            series: charData
        };
    }, [data, isCard]);

    return (
        <ChartContainer>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </ChartContainer>
    )
}

export default MeasurementLineHighchart;

const ChartContainer = styled.div`
  height: 200px;
`;