import { ResponsiveLine } from "@nivo/line";
import moment from "moment";
import React, { useMemo } from "react";
import { TAG_TYPE_COLORS } from "../constants";
import { useTranslation } from 'react-i18next'

function MeasurementSimpleChart({ tag, data, startDate, endDate }) {

  const [t] = useTranslation();

  const chartData = useMemo(() => {
    return [{
      id: tag.type,
      data: data.map((point) => ({
        x: point.time,
        y: point.mean,
      }))
    }]
  }, [data, tag.type]);

  return (
    <ResponsiveLine
      isInteractive
      useMesh
      enableCrosshair
      data={chartData}
      colors={[TAG_TYPE_COLORS[tag.type]]}
      enableArea={true}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: t('dashboards.labels.date'),
        legendOffset: 36,
        legendPosition: "middle",
        format: (value) => moment(value).format("ll"),
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: t('dashboards.labels.average'),
        legendOffset: -40,
        legendPosition: "middle",
        format: ".2s",
      }}
      xScale={{
        type: "linear",
        min: startDate.valueOf(),
        max: endDate.valueOf(),
      }}
      yFormat=",.2~f"
      xFormat={(value) => moment(value).format("lll")}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 100,
          itemHeight: 25,
          itemOpacity: 0.75,
          symbolSize: 10,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      margin={{ top: 50, right: 140, bottom: 50, left: 60 }}
    />
  );
}

export default MeasurementSimpleChart;