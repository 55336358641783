import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { mergeData } from "../utils/functions";
import React, { useMemo } from "react";

function MeasurementScatterPlot({ data }) {

    const mergedData = mergeData(data)

    const chartData = useMemo(() => {
        return [{
            id: 'Data',
            data: mergedData.map((point) => ({
                x: point.mean0 ? point.mean0 : 0,
                y: point.mean1 ? point.mean1 : 0,
            })),
        },
        ];
    }, [mergedData]);

    return (
        <div style={{ flexShrink: false, width: "100%", height: "100%" }}>
            {
                chartData.length > 0 ? (
                    <ResponsiveScatterPlot
                        isInteractive={true}
                        useMesh
                        enableCrosshair
                        data={chartData}
                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: data[0].tag.description + " (" + data[0].tag.well + ")",
                            legendPosition: 'middle',
                            legendOffset: 46,
                            format: ".1f",
                        }}
                        axisLeft={{
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: data[1].tag.description + " (" + data[1].tag.well + ")",
                            legendPosition: 'middle',
                            legendOffset: -60,
                            format: ".1f",
                        }}
                        xScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto'
                        }}
                        yFormat=",.2~f"
                        xFormat=",.2~f"
                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto'
                        }}
                        colors={{ scheme: 'nivo' }}
                        nodeSize={12}
                        axisTop={null}
                        axisRight={null}
                        margin={{
                            top: 20,
                            right: 70,
                            bottom: 70,
                            left: 80
                        }}
                    />
                )
                    : (
                        [<h3>Information not found</h3>])
            }
        </div>
    );
}

export default MeasurementScatterPlot