import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { authLogin, authRefresh } from "../api";

const jwtAuth = {
  isAuthenticated: false,
  refresher: null,
  signin({ username, password, access_token }, cb) {
    const authenticate = access_token
      ? authRefresh()
      : authLogin({ username, password });

    authenticate.then(({ access_token }) => {
      if (access_token) {
        sessionStorage.setItem("petwin_token", access_token);
        this.isAuthenticated = true;
        this.refresher = setInterval(() => {
          authRefresh().then(({ access_token }) => {
            sessionStorage.setItem("petwin_token", access_token);
          });
        }, 1000 * 60);
        cb(username);
      } else {
        cb(null);
      }
    });
  },
  signout(cb) {
    clearInterval(this.refresher);
    this.isAuthenticated = false;
    sessionStorage.removeItem("petwin_token");
    cb(null);
  },
  parseToken(token) {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  },
};

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  const existingAccessToken = useMemo(
    () => sessionStorage.getItem("petwin_token"),
    []
  );

  const [isInitializing, setInitializing] = useState(!!existingAccessToken);

  useEffect(() => {
    if (isInitializing) {
      const { user_id } = jwtAuth.parseToken(existingAccessToken);

      auth.signin(
        { access_token: existingAccessToken, username: user_id },
        () => {
          setInitializing(false);
        }
      );
    }
  }, [auth, existingAccessToken, isInitializing]);

  if (isInitializing) {
    return <div />;
  }

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signin = (data, cb) => {
    return jwtAuth.signin(data, (user) => {
      setUser(user);
      cb(user);
    });
  };

  const signout = (cb) => {
    return jwtAuth.signout(() => {
      setUser(null);
      cb();
    });
  };

  return {
    user,
    signin,
    signout,
  };
}
