import React from "react";
import { useQuery } from "react-query";
import { computeAnomalies } from "../api";
import moment from "moment";
import { getTimeWindow } from "../utils/functions";
import Plot from 'react-plotly.js';
import Loader from "./Loader";
import { useMemo } from "react";

function MeasurementAnomalies({ measurement, startDate, endDate, contamination, maxFeatures, nEstimators }) {

    const { data = [], isLoading } = useQuery(
        startDate &&
        endDate && [
            "measurement",
            {
                measurement: measurement,
                start_date: startDate.toISOString(),
                end_date: endDate.toISOString(),
                time_window: getTimeWindow(startDate, endDate),
                fill: "none",
                n_estimators: nEstimators,
                contamination: contamination,
                max_features: maxFeatures
            },
        ],
        ({ queryKey }) => {
            const [, params] = queryKey;
            return computeAnomalies(params)
        }
    );

    const chartData = useMemo(() => {
        return {
            id: "mean",
            data: data.map((point) => ({
                x: moment(point.time).format("l"),
                y: point.mean,
                anomaly: point.anomaly
            })),
        }
            ;
    }, [data]);


    return (
        <div>
            {isLoading && chartData.data.length <= 0 ? (
                <Loader />
            ) : (

                <Plot
                    data={[
                        {
                            x: chartData.data.flatMap((point) => { return point.x }),
                            y: chartData.data.flatMap((point) => { return point.y }),
                            type: 'areapline',
                            name: 'Data Point',
                            mode: 'lines+markers',
                            marker: { color: 'green' },
                        },
                        {
                            x: chartData.data.filter((t) => t.anomaly !== 1).flatMap((point) => { return point.x }),
                            y: chartData.data.filter((t) => t.anomaly !== 1).flatMap((point) => { return point.y }),
                            type: 'scatter',
                            name: 'Anomaly',
                            mode: 'scatter+markers',
                            marker: { color: 'red' },
                        }
                    ]}
                    layout={{
                        title: 'Anomaly Detection',
                        responsive: true,
                        useResizeHandler: true,
                        autosize: true,
                        padding: 0, margin: 0,
                        xaxis: {
                            title: 'Datetime',
                            titlefont: {
                                family: 'Arial, sans-serif',
                                size: 18,
                                color: 'grey'
                            },
                            showticklabels: true,
                            tickangle: '-35',
                            tickfont: {
                                family: 'Old Standard TT, serif',
                                size: 11,
                                color: 'black'
                            },
                            exponentformat: 'none',
                            showexponent: 'all'
                        },
                        yaxis: {
                            title: 'Values',
                            titlefont: {
                                family: 'Arial, sans-serif',
                                size: 18,
                                color: 'grey'
                            },
                            showticklabels: true,
                            tickangle: '0',
                            tickfont: {
                                family: 'Old Standard TT, serif',
                                size: 14,
                                color: 'black'
                            },
                            exponentformat: 'none',
                            showexponent: 'all'
                        }

                    }}
                    style={{ width: "100%" }}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}

                />
            )}
        </div>
    )
}

export default MeasurementAnomalies;
