import L from "leaflet";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Root from "./components/Root";
import Login from "./modules/Login";
import { ProvideAuth } from "./providers/auth";

L.Icon.Default.imagePath = "assets/";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <ProvideAuth>
      <Router>
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <PrivateRoute path="/">
              <Root />
            </PrivateRoute>
          </Switch>
        </QueryClientProvider>
      </Router>
    </ProvideAuth>
  );
}

export default App;
