import { keyBy, startCase } from "lodash";
import React, { useMemo, useState } from "react";
import { Badge, Col, Container, ListGroup, Row } from "react-bootstrap";
import { Multiselect } from 'multiselect-react-dropdown';
import { useQuery } from "react-query";
import { listMeasurements } from "../api";
import { OIL_PRODUCED_TAG, TAGS_BY_WELL, WELLS, WELLS_BY_TYPE } from "../constants";
import Loader from "../components/Loader";
import MeasurementLine from "../components/MeasurementLine";
import MeasurementMultipleLine from "../components/MeasurementMultipleLine";
import { Trans, useTranslation } from 'react-i18next';
import { OntologyExplorerContext } from "../modules/OntologyExplorer";

function Dashboard({ startDate, endDate }) {

  const { data = [], isLoading } = useQuery("measurements", () =>
    listMeasurements()
  );
  const [t] = useTranslation();
  const showAll = true

  const [cdata, setCdata] = useState(TAGS_BY_WELL)

  const ontologyExplorerContextValue = useMemo(
    () => ({ startDate, endDate, showAll, cdata }),
    [endDate, startDate, showAll, cdata]
  );

  const isTagAvailable = useMemo(() => {
    const existingData = keyBy(data, "name");

    return {
      ...existingData,
      OIL_PRODUCED: { name: "OIL_PRODUCED" },
    };
  }, [data]);

  const opts = [{
    'id': 'temperature',
    'label': 'Temperature'
  }, {

    'id': 'pressure',
    'label': 'Pressure'
  }, {
    'id': 'icv',
    'label': 'ICV'
  }
  ]

  const filterTags = (selectedList) => {
    let filterTags = new Map()
    Object.entries(TAGS_BY_WELL).forEach(([well, tags]) => {
      tags = tags.filter(tagWell => isTagAvailable[tagWell.id])
      let newTags = []
      selectedList.forEach(element => {
        tags.forEach(tagWell => {
          if (tagWell.description.toLowerCase().includes(element.label.toLowerCase())) {
            if (!newTags.includes(tagWell))
              newTags.push(tagWell)
          }
        });
      });
      filterTags[well] = newTags
    })
    setCdata(filterTags)
  }


  const onSelect = (selectedList, selectedItem) => {
    filterTags(selectedList)
  }

  const onRemove = (selectedList, removedItem) => {
    selectedList.length === 0 ? setCdata(TAGS_BY_WELL) : filterTags(selectedList)
  }

  return (
    <div style={isLoading ? { height: 300 } : null}>
      {isLoading ? (
        <Loader />
      ) : (
        <Container fluid>
          <Row className="mb-2">
            <Col>
              <div className="panel panel-default">
                <div className="panel-body">
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <MeasurementMultipleLine
                        tag={OIL_PRODUCED_TAG}
                        startDate={startDate}
                        endDate={endDate} />
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="m-2">
            <Multiselect
              placeholder={t('dashboards.labels.filterTags')}
              options={opts}
              displayValue="label"
              groupBy="well"
              onSelect={onSelect}
              selectionLimit={4}
              closeIcon='cancel'
              onRemove={onRemove}
              showCheckbox={true}
            />
          </Row>
          <Row className="m-2">
            {Object.entries(WELLS_BY_TYPE).map(([type, name]) => {
              return (
                <div class={"col-md-" + 12 / Object.entries(WELLS_BY_TYPE).length}>
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h3 className="panel-title d-flex align-items-center">
                        <h2 className="ml-8">
                          <Badge pill variant="dark">
                            {startCase(type)}
                          </Badge>
                        </h2>
                      </h3>
                    </div>
                    <div className="panel-body">
                      {Object.entries(cdata).filter(([well]) => WELLS[well].type === type).map(([well, tags]) => {
                        return (
                          <div>
                            <h3 className="panel-title d-flex align-items-center">
                              <Trans i18nKey="common.labels.well" /> {well}{" "}
                            </h3>
                            <ListGroup variant="flush">
                              {tags
                                .filter((t) => t.id !== "OIL_PRODUCED")
                                .flatMap((tag) => {
                                  return isTagAvailable[tag.id] ? (
                                    <ListGroup.Item key={tag.id}>
                                      <OntologyExplorerContext.Provider value={ontologyExplorerContextValue}>
                                        <MeasurementLine
                                          measurementData={data}
                                          tag={tag}
                                        />
                                      </OntologyExplorerContext.Provider>
                                    </ListGroup.Item>
                                  ) : ([]);
                                })}
                            </ListGroup>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            })}
          </Row>
        </Container>
      )}
    </div>
  );
}

export default Dashboard;
